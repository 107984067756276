import Vue from 'vue'
import Router from 'vue-router'
import Users from '@/components/Users'
import User from '@/components/User'
import Planning from '@/components/Planning'
import Projects from '@/components/Projects'
import Project from '@/components/Project'
import Companies from '@/components/Companies'
import Company from '@/components/Company'
import Branches from '@/components/Branches'
import Branche from '@/components/Branche'
import HourCategories from '@/components/HourCategories'
import Hourcodes from '@/components/HourCodes'
import Tags from '@/components/Tags'
// import Contracts from '@/components/Contracts'
// import ProjectHours from '@/components/ProjectHours'
import PlanningPerWeek from '@/components/PlanningPerWeek'
import Declaraties from '@/components/Declaraties'
import Documents from '@/components/Documents'
import Hours from '@/components/Hours'
import Invoices from '@/components/Invoices'
import Search from '@/components/Search'
import UserHours from '@/components/UserHours'
import Settings from '@/components/Settings'
import Admin from '@/components/Admin'
import Finance from '@/components/Finance'
import UserTasks from '@/components/UserTasks'
import ZZPRProject from '@/components/ZZPR-Project'
import EmailSenders from '@/components/EmailSenders'
import PublicProjects from '@/components/ProjectsPublic'
import PublicProject from '@/components/ProjectPublic'
import Login from '@/components/Login'
import RootView from '@/components/RootView'
import Register from '@/components/Register'
import NewProject from '@/components/NewProject'
import AboutApp from '@/components/AboutApp'
import AboutZZPR from '@/components/AboutZZPR'
import Privacy from '@/components/Privacy'
import Terms from '@/components/Terms'
import AppPages from '@/components/AppPages'
import NewPassword from '@/components/NewPassword'
import BicCodes from '@/components/BicCodes'
import SuperAdmins from '@/components/SuperAdmins'
import SMSSettings from '@/components/SMSSettings'
import CRONSettings from '@/components/CRONSettings'
import AboutFlyingFreelancers from '@/components/AboutFlyingFreelancers'
import Dashboard from '@/components/Dashboard'
import UserNotifications from '@/components/UserNotifications'
import License from '@/components/License'
import Todo from '@/components/Todo'
import Modules from '@/components/Modules'
import Metas from '@/components/Metas'
import PMS from '@/components/PMs'
import FFinvoice from '@/components/FFinvoice'
import Usage from '@/components/Usage'
import NewsLetter from '@/components/NewsLetter'
import FFWax from '@/components/FFWax'
import Resources from '@/components/Resources'
import Carousel from '../components/Carousel.vue'
import CarouselSettings from '../components/CarouselSettings.vue'
import DefaultSettings from '../components/DefaultSettings.vue'
import test from '../components/test.vue'

Vue.use(Router)
let routes = []
 routes= [
  // {
  //   path: '/',
  //   name: 'Users',
  //   component: Users
  // },
  {
    path: '/test/:id',
    name: 'Test',
    component: test,
    props:true
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    props: true

  },
  {
    path: '/planning',
    name: 'Planning',
    component: Planning
  },
  {
    path: '/planningperweek',
    name: 'PlanningPerWeek',
    component: PlanningPerWeek
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: Carousel
  },  
  {
    path: '/company/:id',
    name: 'Company',
    component: Company,
    props: true
  },
  // {
  //   path: '/contracts/',
  //   name: 'Contracts',
  //   component: Contracts,
  // },
  // {
  //   path: '/project_hours/',
  //   name: 'ProjectHours',
  //   component: ProjectHours,
  // },

  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    props: true
  },
  {
    path: '/projectinfo/:id',
    name: 'zzprProject',
    component: ZZPRProject,
    props: true
  },
  {
    path: '/branches',
    name: 'Branches',
    component: Branches
  },
  {
    path: '/branche/:id',
    name: 'Branche',
    component: Branche,
    props: true
  },
  {
    path: '/hourcategories',
    name: 'Hourcategories',
    component: HourCategories
  },
  {
    path: '/hours/:id',
    name: 'Hourcodes',
    component: Hourcodes,
    props: true
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/declaraties',
    name: 'Declaraties',
    component: Declaraties,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/hours',
    name: 'Hours',
    component: Hours
  },
  {
    path: '/invoices/:sale',
    name: 'Invoices',
    component: Invoices,
    props:true
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/userhours/:id/:week/:year',
    name: 'UserHours',
    component: UserHours
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  { path : '/notifications',
    name: 'UserNotifications',
    component:UserNotifications
  },
  {
    path: '/tasks/:id',
    name: 'UserTasks',
    component: UserTasks,
    props:true

  },
  {
    path: '/emailsenders',
    name: 'EmailSenders',
    component: EmailSenders
  },
  // public 
  {
    path: '/public_projects',
    name: 'PublicProjects',
    component: PublicProjects
  },
  {
    path: '/public_project/:id',
    name: 'PublicProject',
    component: PublicProject
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  // {
  //   path: '/',
  //   name: 'RootView',
  //   component: RootView
  // },
  {
    path: '/reset_password/:token',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },    
  { 
    path: '/new_project',
    name: 'NewProject',
    component: NewProject
  },
  {
    path:'/aboutapp',
    name:'AboutApp',
    component:AboutApp
  },
  {
    path: '/aboutcompany',
    name: 'AboutZZPR',
    component: AboutZZPR
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },  
  {
    path: '/apppages',
    name: 'AppPages',
    component: AppPages
  },
  {
    path: '/biccodes',
    name: 'BicCodes',
    component: BicCodes
  },
  {
    path: '/superadmins',
    name: 'SuperAdmins',
    component: SuperAdmins
  },
  {
    path: '/license',
    name: 'License',
    component: License
  },
  {
    path: '/todo',
    name: 'Todo',
    component: Todo
  },  
  {
    path: '/smssettings',
    name: 'SMSSettings',
    component: SMSSettings
  },
  {
    path: '/cronsettings',
    name: 'CRONSettings',
    component: CRONSettings
  },         
  {
    path: '/aboutff',
    name: 'AboutFF',
    component:AboutFlyingFreelancers
  },
  {
    path: '/modules',
    name: 'Modules',
    component: Modules
  },
  {
    path: '/metas',
    name: "Metas",
    component: Metas
  },
  {
    path: '/pms',
    name: 'PMS',
    component:PMS
  },
  {
    path: '/pms/:id',
    name: 'pmspriv',
    component: PMS
  },  
  {
    path: '/ffinvoice',
    name: 'ffinvoice',
    component: FFinvoice
  },
  {
    path: '/usage',
    name: 'usage',
    component: Usage
  },
  {
    path:'/newsletter',
    name:'newsletter',
    component:NewsLetter
  },
  {
      path:'/schedule',
      name:'Schedule',
      component: FFWax
  },
  {
    path:'/resources',
    name:'Resources',
    component: Resources
  },
  {
    path:'/carouselsettings',
    name: 'CarouselSettings',
    component:CarouselSettings
  },
  {
    path:'/defaultsettings',
    name: 'DefaultSettings',
    component:DefaultSettings
  }
]

if (process.env.VUE_APP_BUILD_TARGET=="apprelease") {
  // routes = []
  // routes= [
  //   {
  //     path: '/user/:id',
  //     name: 'User',
  //     component: User,
  //     props: true
  
  //   },
  //   {
  //     path: '/projects',
  //     name: 'Projects',
  //     component: Projects
  //   },
  //   {
  //     path: '/project/:id',
  //     name: 'Project',
  //     component: Project,
  //     props: true
  //   },
  //   {
  //     path: '/projectinfo/:id',
  //     name: 'zzprProject',
  //     component: ZZPRProject,
  //     props: true
  //   },
  //   {
  //     path: '/userhours/:id/:week/:year',
  //     name: 'UserHours',
  //     component: UserHours
  //   },
  //   { path : '/notifications',
  //     name: 'UserNotifications',
  //     component:UserNotifications
  //   },
  //   {
  //     path: '/tasks/:id',
  //     name: 'UserTasks',
  //     component: UserTasks,
  //     props:true
  //   },
  //   // public 
  //   {
  //     path: '/public_projects',
  //     name: 'PublicProjects',
  //     component: PublicProjects
  //   },
  //   {
  //     path: '/public_project/:id',
  //     name: 'PublicProject',
  //     component: PublicProject
  //   },
  //   {
  //     path: '/reset_password/:token',
  //     name: 'NewPassword',
  //     component: NewPassword
  //   },
  //   {
  //     path: '/register',
  //     name: 'Register',
  //     component: Register
  //   },    
  //   { 
  //     path: '/new_project',
  //     name: 'NewProject',
  //     component: NewProject
  //   },
  //   {
  //     path:'/aboutapp',
  //     name:'AboutApp',
  //     component:AboutApp
  //   },
  //   {
  //     path: '/aboutcompany',
  //     name: 'AboutZZPR',
  //     component: AboutZZPR
  //   },
  //   {
  //     path: '/privacy',
  //     name: 'Privacy',
  //     component: Privacy
  //   },
  //   {
  //     path: '/terms',
  //     name: 'Terms',
  //     component: Terms
  //   },  
  //   {
  //     path: '/apppages',
  //     name: 'AppPages',
  //     component: AppPages
  //   },
  //   {
  //     path: '/aboutff',
  //     name: 'AboutFF',
  //     component:AboutFlyingFreelancers
  //   },
  //   {
  //     path: '/pms',
  //     name: 'PMS',
  //     component:PMS
  //   },
  //   {
  //     path: '/pms/:id',
  //     name: 'pmspriv',
  //     component: PMS
  //   },  
  // ]  
  routes.push(
    {
      path: '/',
      name: 'RootView',
      component: RootView
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },    
  )
} else {
  routes.push(
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/rootview',
      name: 'RootView',
      component: RootView
    },    
  )
}
const router = new Router({
routes


})


export default router