<template>
  <b-container v-if="createAny('users')"  fluid>
    <input type="hidden" :key="componentKey" />

    <!-- User status -->
    <div id="user-status" :style="statusColor" cols="4" v-if="createAny('users')">
      <!-- {{ $t("meta.extra") }}:  -->{{ userstatus() }}
    </div>
    <!-- Titlebar -->
    <div class="title-bar">
      <template v-if="createAny('users')">
        <h1 v-if="user && user.role == 'user'">Zzp'er: {{ username_by_id(user.id) }} </h1>
        <h1 v-else>{{ user.role }}: {{ username_by_id(user.id) }}</h1>
      </template>

      <h1 v-else>{{ username_by_id(user.id) }}</h1>
      <div>
        <b-button v-if="this.settings.AddCreditInvoice" href="javascript:;" variant="primary" class="btn-icon" @click="showCreditInvoice()" :title="$t('buttons.addcreditinvoice')">
          <font-awesome-icon icon="euro-sign" />
        </b-button>        
        <b-button v-if="createAny('users') && smsEnabled()" href="javascript:;" variant="primary" class="btn-icon" @click="sendSMSUser()" :title="$t('labels.sendsms')">
          <font-awesome-icon icon="sms" />
        </b-button>
        <b-button v-if="user.id != current_user.id && user.token" variant="primary" class="btn-icon" @click="notify_user()">
          <font-awesome-icon icon="bell" />
        </b-button>  
        <b-button v-if="this.settings.ShowEmailButton && user.id != current_user.id" variant="primary" class="btn-icon" @click="contact_user()">
          <font-awesome-icon icon="envelope" />
        </b-button>
        <b-button v-else href="tel:0413379279" variant="primary">
          <font-awesome-icon icon="phone-alt" />{{ $t("buttons.contactuser") }}
        </b-button>
        <b-button v-if="false && user.role=='user' && settings.EnableHourPlanning" @click="weekplanning()" variant="primary"  class="btn-icon">
          <font-awesome-icon icon="calendar" />
        </b-button>
        <b-button v-if="user.role=='user' && settings.EnableVacationPlanning" @click="waxVacation()" variant="primary"  class="btn-icon">
          <font-awesome-icon icon="calendar" />
        </b-button>
        
        <b-button v-if="createAny('users')" variant="primary" @click="editUserModal(user.id)">
          <font-awesome-icon icon="cog" /> {{ current_user.role != "user" ? $t("labels.edit") :
          $t("labels.settingszzpr")
          }}
        </b-button>
      </div>
    </div>



    <b-row>
      <b-col>

        <!-- User holder -->
        <div id="user-holder" :style="blacklist">

          <!-- User photo -->
          <div id="user-photo-holder" v-if="user.id">
            <b-img :src="profileto(user.id)" fluid></b-img>
          </div>
          <!-- <img :src='"/api/media_profiles/"+user.id+".jpg"'/> -->
          <!-- User details -->
          <div id="user-details">
            <h3>{{ username_by_id(user.id) }}</h3>
            <h4 v-if="user &&user.role=='user'">{{$t('labels.companyname') }} {{ this.user.companyname }}</h4>
            <p>
              {{ user.adres }} {{ user.housenumber }}<br v-if="user.adres || user.housenumber" />
              {{ user.postcode }} {{ user.city }}<br v-if="user.postcode || user.city" />
              {{ user.province }}<br v-if="user.province" />
              {{ user.country }}
            </p>

            <!-- Contact -->
            <div id="user-contact" v-if="createAny('users')">
              <!-- <h3>{{ $t("labels.contact") }}</h3> -->
              <p>
                {{ $t("labels.age") }}<br />
                {{ this.age }}
              </p>

              <p v-if='this.user.email != undefined'>
                {{ $t("labels.email") }}<br />
                <a :href=mailto(this.user.email)>
                  {{ user.email }}
                </a>
              </p>

              <p v-if='this.user.mobile != undefined'>
                {{ $t("meta.mobiel") }}<br />
                <a :href=phoneto(this.user.mobile)>
                  {{ mobielformat }}
                </a>
              </p>

              <p v-if='this.user.phone != undefined'>
                {{ $t("meta.phone") }}<br />
                <a :href=phoneto(this.user.phone)>
                  {{ user.phone }}
                </a>
              </p>
            </div>

            <!-- Function -->
            <h3 v-if="createAny('users') && this.user.userfunction != undefined">{{ $t("meta.userfunction") }}</h3>
            <p v-if="createAny('users') && this.user.userfunction != undefined">
              {{ user.userfunction }}
            </p>
          </div>
        </div>
      </b-col>

      <!-- Settings -->
      <b-col lg="6" v-if="user.id == current_user.id && current_user.role == 'user'">
        <h3>{{ $t("labels.settingszzpr") }}</h3>
        <dl>
          <dt>{{ $t("labels.kvk") }}</dt>
          <dd>{{ user.kvkcode }}</dd>
          <dt>{{ $t("labels.vat") }}</dt>
          <dd>{{ user.vatcode }}</dd>
          <dt>{{ $t("labels.iban") }}</dt>
          <dd>{{ user.iban }}</dd>
          <dt>{{ $t("labels.BIC") }}</dt>
          <dd>{{ user.bic }}</dd>
        </dl>
      </b-col>

      <!-- Scores -->
      <b-col v-if="user.role == 'user' && createAny('users')">
        <h3>{{ $t("labels.scores") }} </h3>
        <b-row>
          <b-col v-if="user.V != undefined" cols="4">V={{ this.formatFloat(user.V) }}</b-col>
          <b-col v-if="user.V != undefined" cols="7">{{ user.Vremarks }}</b-col>
          <b-col v-if="user.A != undefined" cols="4">A={{ this.formatFloat(user.A) }}</b-col>
          <b-col v-if="user.A != undefined" cols="7">{{ user.Aremarks }}</b-col>
          <b-col v-if="user.K != undefined" cols="4">K={{ this.formatFloat(user.K) }}</b-col>
          <b-col v-if="user.K != undefined" cols="7">{{ user.Kremarks }}</b-col>
          <b-col cols="4">{{ $t("meta.waardering") }}</b-col>
          <b-col cols="7">{{ user.rating }}</b-col>
          <b-col cols="4">{{ $t("labels.addedby") }}</b-col>
          <b-col cols="7">{{ this.username_by_id(user.owner_id) }}</b-col>
          <b-col cols="4">{{ $t("meta.extra") }}</b-col>
          <b-col cols="7">{{ this.userstatus() }}</b-col>
          <b-col cols="4" v-if="user.available_hours != undefined">{{ $t("labels.available_hours") }}</b-col>
          <b-col v-if="user.available_hours != undefined" cols="7">{{ user.available_hours }}
            <small>{{ user.available_hours_remarks }}</small>
          </b-col>
          <b-col cols="4" v-if="user.available_from != undefined">{{ $t("labels.available_from") }}</b-col>
          <b-col cols="7" v-if="user.available_from != undefined">{{ this.formatDate(user.available_from) }}</b-col>
          <b-col cols="4">{{ $t("meta.speaks") }}</b-col>
          <b-col cols="7">{{ user.speaks }}</b-col>
        </b-row>

        <h3 v-if="user.blockcompanies.length>0">{{ $t("labels.blockingcompanies") }}</h3>
        <div v-for="(com, index) in user.blockcompanies" :key="index">
          <a v-if="current_user.role == 'admin'" @click="show_company(com.company_id)" class="link-item"
                style="cursor:pointer"> {{ company_by_id(com.company_id).company_name }} </a>
          </div>
          <h3>{{ $t("titles.tags") }}</h3>
          <ul>
            <li v-for="tag_id in user.tags" :key="tag_id">{{ tag_by_id(tag_id.tag_id).tag }} </li>
          </ul>
      </b-col>



      <!-- Responsibilities -->
      <b-col lg="6" v-if="user.role == 'contactpersoon' || user.role == 'opdrachtgever'">
        <div id="user-responsibilities">
          <h3 v-if="createAny('users')">{{ $t("labels.responsibilities") }}</h3>

          <ul>
            <li @click="company_contact">
              <font-awesome-icon :class="{active: user.company_contact == '1'}" icon="address-book" /> {{
              $t("labels.company_contact") }}
            </li>
            <li @click="document_contact">
              <font-awesome-icon :class="{active: user.document_contact == '1'}" icon="file" /> {{
              $t("labels.document_contact") }}
            </li>
            <li @click="invoice_contant">
              <font-awesome-icon :class="{active: user.invoice_contant == '1'}" icon="euro-sign" /> {{
              $t("labels.invoice_contant") }}
            </li>
            <li @click="sign_contact">
              <font-awesome-icon :class="{active: user.sign_contact == '1'}" icon="pencil-alt" />
              {{
              $t("labels.sign_contact") }}
            </li>
            <li @click="location_contact">
              <font-awesome-icon :class="{active: user.location_contact == '1'}" icon="street-view" /> {{
              $t("labels.location_contact") }}
            </li>
          </ul>
        </div>
        <div id="user-responsibilities">
          <h3 v-if="createAny('users')">{{ $t("meta.speaks") }}</h3>
          <span>{{ user.speaks }}</span>
        </div>
        <!-- Client -->
        <div id="user-client">
          <h3>{{ $t("labels.client") }}</h3>
          <ul v-for="company in companies" :key="'cc' + company.id">
            <li v-if="company.contactpersons.indexOf(user.id) != -1">
              <a v-if="current_user.role == 'admin'" @click="show_company(company.id)" class="link-item"
                style="cursor:pointer;font-size:2rem;"> {{ company.company_name }} </a>  <img style="height: 8rem;width: 8rem;object-fit: contain;" :src="companyimageto(company.id)" /><br />
              <p v-if="current_user.role != 'admin'">{{ company.company_name }} </p>
            

            </li>
          </ul>
        </div>
      </b-col>
    </b-row>

    <!-- Documents -->
    <section v-if="user.id == current_user.id" id="documents">
      <h2>{{ $t("labels.UserDocuments") }} </h2>
      <b-table :items="docs" :fields="docfields" @row-clicked="onDocumentsClicked" >
        <template #cell()="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span> {{ row.value }}
        </template>
      </b-table>
    </section>
    <div v-if="showdata">
    <b-row v-if="createAny('users')">
      <b-col cols="6" v-if="user.role == 'user' || user.role == 'contactpersoon' && createAny('users')">
        {{ $t('meta.remarks') }} {{ user.callat }}<br />
        <b-input-group v-if="createAny('users')">
          <b-form-textarea size="sm" rows="8" v-bind:type="callbacknote" v-bind:id="'bind-remarks'" :ref="callbacknote"
            class="form-control" v-model="user['callbacknote']" @blur="saveuser()" />
          <b-input-group-append>
            <b-button squared class="multi-append" href="javascript:;"
              @click="append_data_multi('callbacknote')">
              <font-awesome-icon icon="plus-circle" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <pre v-else>{{ user['callbacknote'] }}</pre>

        <br>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
          <div class="col-3">
            <label for=""> {{ $t("labels.available_hours") }}</label>
          </div>
          <div class="col-4">
            <b-form-input type="number" class="form-control" v-model="user.available_hours" />
          </div>
          <div class="col-5">
            <b-input-group>
              <b-form-textarea size="sm" rows="3" class="form-control" v-model="user.available_hours_remarks"
                :ref="available_hours_remarks" />
              <b-input-group-append v-if="createAny('users')">
                <b-button squared class="multi-append" href="javascript:;"
                  @click="append_data_multi('available_hours_remarks')">
                  <font-awesome-icon icon="plus-circle" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
          <div class="col-3">
            <label for=""> {{ $t("labels.available_from") }}</label>
          </div>
          <div class="col-4">
            <flat-pickr v-model="user.available_from" :config="config" class="form-control" placeholder="Select date"
              name="startdate" />
          </div>
        </div>

        <a href="javascript:;" v-if="checkcallat == 2" class="btn btn-primary float-left"
          @click="callUserModal(user.id)">
          <font-awesome-icon icon="exclamation" style="color: red;" />
          <font-awesome-icon icon="phone" /> {{$t('labels.call')}}
        </a>
        <a href="javascript:;" v-else-if="checkcallat == 1" class="btn btn-primary float-left"
          @click="callUserModal(user.id)">
          <font-awesome-icon icon="phone" /> {{$t('labels.call')}}
        </a>
        <a v-else href="javascript:;" class="btn btn-primary float-left" @click="callUserModal(user.id)">
          <font-awesome-icon icon="phone" /> {{$t('labels.call')}}
        </a>

        <a href="javascript:;" v-if="user.role == 'user' || user.role == 'contactpersoon' && createAny('users')"
          class="btn btn-primary float-right" @click="saveuser()">
          <font-awesome-icon icon="save" />
        </a>

        <br /><br /><br />
        <div v-if="hasbirthday()">
          <font-awesome-icon class="table-contacts-roles" icon="birthday-cake" />
          {{ dateToNL(user.birthday) }}
        </div>
        <br />
        <h3 v-if="user.friends.length > 0">{{ $t("labels.friends") }}</h3>
        <table>
          <tbody>
            <tr>
              <td v-for="friend in user.friends" v-bind:key="'friend' + friend.id">
                <a @click="show_friend(friend.friend_id)" :style="{ cursor: 'pointer' }">
                  <img style="height: 5rem;width: 5rem;object-fit: contain;"
                  :src="profileto(friend.friend_id)"  />

                </a><br>
                {{ username_by_id(friend.friend_id) }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col cols="2">
        <h3 v-if="user.role=='user'">{{ $t("labels.workfields") }}</h3>
        <h3 v-else>{{ $t("labels.branche") }}</h3>
        <span v-html="this.brancheName()"></span>
      </b-col>
      <b-col v-if="docs.length>0">
          <h3>{{ $t("labels.UserDocuments") }} </h3>
        <b-table :items="docs" :tbody-tr-class="rowClass" :fields="docfields" @row-clicked="onDocumentsClicked">
          <template #cell(link)="row">
            <a :href=docto(row.value) v-bind:target="'_blank'">{{ row.item.name }}</a>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="user.role == 'user' || user.role == 'contactpersoon' || user.role == 'opdrachtgever'">
      <b-col v-if="createAny('users')">
        <h2>{{ $t("titles.tasks") }} </h2>
        <b-form-checkbox v-model="show_archive" name="checkbox-1" value="1" unchecked-value="0">
          {{ $t('labels.showarchive') }}</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="user.role == 'user' || user.role == 'contactpersoon' || user.role == 'opdrachtgever'">
      <!-- Admin -->
      <b-col v-if="createAny('users')">
        <AdminProjectsOverview :user_id="user.id" :show_archive="show_archive" />
        <div class="text-right">
          <b-button v-if="user.role == 'user' && this.freeprojects()" variant="primary"
            @click="addContractModal(user.id)">
            <font-awesome-icon icon="plus" /> {{ $t("labels.addcontract") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="(user.role == 'contactpersoon' || user.role == 'opdrachtgever') && branche_projects.length>0 ">
      <!-- Admin -->
      <b-col v-if="createAny('users')">
        <h2>{{$t('titles.projects')}}</h2>
        <b-table :items="branche_projects" :fields="branche_fields">
          <template #cell(pulldown)="row">
            <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
              <b-dropdown-item v-if="row.item.zzprusers  && row.item.zzprusers.length==0" @click="confirm_delete_project(row.item.id)">
                <font-awesome-icon icon="trash" /> {{$t('labels.deleteproject')}}
              </b-dropdown-item>
              <b-dropdown-item @click="editProjectModal(row.item)">
                <font-awesome-icon icon="cog" /> {{$t('labels.editproject')}}
              </b-dropdown-item>
              <b-dropdown-item  v-if="false" @click="clone_project(row.item)">
                <font-awesome-icon icon="clone" />lalala {{$t('labels.duplicate')}}
              </b-dropdown-item>
              <b-dropdown-item @click="notifyUsersForProject(row.item)">
                <font-awesome-icon icon="exclamation" /> {{$t('labels.notify')}}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(projectnumber)="row">
            {{ row.item.projectnumber }}
          </template>
          <template #cell(extra)="row">
            <span v-html='status(row.item.extra)'></span>
          </template>
          <template #cell(id)="row">
            <span v-html='companyInfo(row.item)'></span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <BSCreditInvoice :user_id="user_id"/>
  </div>
    <!-- <BSWeekPlanning :user_id="user_id"/>
    <BSWaxVacation :user_id="user_id"/> -->
  </b-container>
  <b-container v-else fluid>
    <input type="hidden" :key="componentKey" />

    <!-- Titlebar -->
    <div class="title-bar">
      <h3 :style="AppSecondaryColor()" style="margin-top:20px"> {{$t('titles.profilepage') }} </h3>
    </div>
    <b-row>
      <b-col cols="4">

        <!-- User holder -->
        <div id="user-holder">

          <!-- User photo -->
          <div id="user-photo-holder">
            <b-img :src="profileto(user.id)" fluid></b-img>
          </div>
        </div>
        </b-col>
        <b-col>
          <!-- User details -->
          <div id="user-details">
            <h3 :style="AppSecondaryColor()">{{ username_by_id(user.id) }}</h3>
            <p v-if="user.id == current_user.id && current_user.role == 'user'" :style="AppSecondaryColor()">
              {{ user.adres }} {{ user.housenumber }}<br v-if="user.adres || user.housenumber" />
              {{ user.postcode }} {{ user.city }}<br v-if="user.postcode || user.city" />
              {{ user.province }}<br v-if="user.province" />
              {{ user.country }}
            </p>
            <b-form-checkbox v-model="user.newsletter" @change="saveMe()" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.newsletter")
                }}</b-form-checkbox>
            </div>
        </b-col>
      </b-row>
      <h2 v-if="user &&user.role=='user'" :style="AppSecondaryColor()">{{ this.user.companyname }}</h2>
      <!-- Settings -->
      <b-row>
      <b-col lg="6" v-if="user.id == current_user.id && current_user.role == 'user'">
        <dl :style="AppSecondaryColor()">
          <dt>{{ $t("labels.vat") }}</dt>
          <dd>{{ user.vatcode }}</dd>
          <dt>{{ $t("labels.iban") }}</dt>
          <dd>{{ user.iban }}</dd>
          <dt>{{ $t("labels.BIC") }}</dt>
          <dd>{{ user.bic }}</dd>
          <dt v-if="user.identificationcode">{{ $t("labels.identificationdoc")}}</dt>
          <dd v-if="user.identification_endate">{{ user.identificationcode}}<br>{{ $t("labels.expirationdate")}}<br><strong> {{dateToNL(user.identification_endate) }}</strong></dd>

          <dt v-if="docKVK">{{ $t("labels.kvk")}}</dt>
          <dd v-if="docKVK">{{ $t("labels.expirationdate")}}<br><b-row><b-col><strong> {{dateToNL(docKVK.expires) }}</strong></b-col><b-col><font-awesome-icon icon="eye" :style="AppPrimaryColor()"  @click="onDocumentsClicked(docKVK)"/></b-col></b-row> </dd>
          <dt v-if="docVCA">{{ $t("labels.vca")}}</dt>
          <dd v-if="docVCA">{{ $t("labels.expirationdate")}}<br><b-row><b-col><strong> {{dateToNL(docVCA.expires) }}</strong></b-col><b-col><font-awesome-icon icon="eye" :style="AppPrimaryColor()"  @click="onDocumentsClicked(docVCA)" /></b-col></b-row> </dd>
          <dt v-if="docGPI">{{ $t("labels.gpi")}}</dt>
          <dd v-if="docGPI">{{ $t("labels.expirationdate")}}<br><b-row><b-col><strong> {{dateToNL(docGPI.expires) }}</strong></b-col><b-col><font-awesome-icon icon="eye" :style="AppPrimaryColor()"  @click="onDocumentsClicked(docGPI)"/></b-col></b-row> </dd>

        </dl>
      </b-col>
      <!-- Responsibilities -->
      <b-col lg="6" v-if="user.role == 'contactpersoon' || user.role == 'opdrachtgever'">
        <div id="user-responsibilities">
          <h3 v-if="createAny('users')">{{ $t("labels.responsibilities") }}</h3>

          <ul>
            <li @click="company_contact">
              <font-awesome-icon :class="{active: user.company_contact == '1'}" icon="address-book" /> {{
              $t("labels.company_contact") }}
            </li>
            <li @click="document_contact">
              <font-awesome-icon :class="{active: user.document_contact == '1'}" icon="file" /> {{
              $t("labels.document_contact") }}
            </li>
            <li @click="invoice_contant">
              <font-awesome-icon :class="{active: user.invoice_contant == '1'}" icon="euro-sign" /> {{
              $t("labels.invoice_contant") }}
            </li>
            <li @click="sign_contact">
              <font-awesome-icon :class="{active: user.sign_contact == '1'}" icon="pencil-alt" />
              {{
              $t("labels.sign_contact") }}
            </li>
            <li @click="location_contact">
              <font-awesome-icon :class="{active: user.location_contact == '1'}" icon="street-view" /> {{
              $t("labels.location_contact") }}
            </li>
          </ul>
        </div>

        <!-- Client -->
        <div id="user-client">
          <h3>{{ $t("labels.client") }}</h3>
          <ul>
            <div v-for="company in companies" :key="'cc' + company.id">
              <li v-if="company.contactpersons.indexOf(user.id) != -1">
                {{ company.company_name }} 
              </li>
            </div>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import message_modal from './modals/MessageModal.vue'
import contact_user_modal from './modals/ContactUser'
import msal_contact_user_modal from './modals/MSALContactUser'
import notify_user_modal from './modals/NotifyUser'
import send_sms_modal from './modals/SendSMSModal'
import BSCreditInvoice from './modals/BSCreditInvoice.vue'
// import BSWeekPlanning from './modals/BSWeekPlanning.vue'
// import BSWaxVacation from './modals/BSWaxVacation.vue'

// import UserProjectsOverview from './parts/UserProjectsOverview.vue'
// import SignerProjectsOverview from './parts/SignerProjectsOverview.vue'
import AdminProjectsOverview from './parts/AdminProjectsOverview.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

export default {
  name: 'user-detail',
  data() {
    return {
      callbacknote: "callbacknote",
      available_hours_remarks: "available_hours_remarks",
      componentKey: 0,
      selectedID: null,
      statusColor: 'background: #ff0000',
      show_archive: "0",
      user_id:0,
      showdata:false,
      config: {
        weekNumbers: true,
        locale: Dutch,
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "Y-m-d",
      }

    }
  },
  mounted() {
     this.user_id = Number(this.$route.params.id)
     setTimeout(()=>{this.showdata=true}, 10)
  },

  computed: {
    user() {
        return this.user_by_id(this.$route.params.id)
    },
    docID(){
      return this.user.documents.filter(d => d.name == "ID")[0]
    },
    docKVK(){
      return this.user.documents.filter(d => d.name == "KvK")[0]
    },
    docVCA(){
      return this.user.documents.filter(d => d.name == "VCA")[0]
    },
    docGPI(){
      return this.user.documents.filter(d => d.name == "GPI")[0]
    },
    mobielformat(){
      var cleaned = ('' + this.user.mobile).replace(/\D/g, '');
      var match = cleaned.match(/^(2|)?(\d{1)(\d{4})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+31 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      } 
      var match2 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
      if (match2) {
        return [ match2[1], '-', match2[2], ' ', match2[3]].join('');
      }       
      return this.user.mobile
    }, 
    checkcallat() {
      if (this.user.callat == null) {
        return 0
      } else {
        let calldate = new Date(this.user.callat)
        var today = new Date();
        var diff = (calldate.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if (calldate < today || diff < 14) {
          return 2
        }
      }
      return 1
    },
    age() {
      var today = new Date();
      var birthDate = new Date(this.user.birthday);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return isNaN(age) ? "?" : age;
    },
    docfields() {
      if(this.current_user.role=="admin"){
        return [
        { key: 'name', label: this.$i18n.t('labels.link') },
        // { key:'code', label:this.$i18n.t('labels.identificationnumber')},
        { key: 'expires', label: this.$i18n.t('labels.expirationdate'),formatter: this.dateToNL },
      ]
      } else {
        return [
        { key: 'name', label: this.$i18n.t('labels.link') },
        { key:'code', label:this.$i18n.t('labels.identificationnumber')},
      ]
      }
    },
    docs() {
      var retdocs = []
      var docsToShow = ["KvK", "VCA","ID", "GPI"]
      this.user.documents.forEach(userdoc => {
        if (docsToShow.find(element => element == userdoc.name) || this.createAny('users')) {
          retdocs.push(userdoc)
          userdoc.code = ""
          if(userdoc.name=="KvK"){
            userdoc.code = this.user.kvkcode
          }
          if(userdoc.name=="ID"){
            userdoc.code = this.user.identificationcode
          }          
        }
      });
      if(this.user.identificationcode){
        let userdoc={}
        userdoc.code = this.user.identificationcode
        userdoc.expires = this.user.identification_endate
        userdoc.name = "ID"
        userdoc.id = 900000
        retdocs.unshift(userdoc)
      }
      // if(this.createAny('users')){
      //   return this.user.documents
      // } else {
        return retdocs
      // }

    },
    branche_projects() {
      return this.projects.filter(p => p.user_id == this.user.id)
        
    },
    blacklist() {
      if(this.user.blacklist==1)
        {return 'background-color:rgba(0,0,0,0.5)'}
        return ""
    },
    branche_fields() {
      if (this.createAny('project')) {
        return [
          { key: 'publishdate', formatter: this.formatDate, label: this.$i18n.t('meta.aangemaakt'), sortable: true, sortDirection: 'desc' },
          { key: 'id', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
          { key: 'projectnumber', label: this.$i18n.t('meta.projectrefnumber') ,sortable: true, sortDirection: 'desc'},
          { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
          { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
          { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
          { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
          { key: 'pulldown', label: this.$i18n.t('labels.actions') },
        ]
      } else {
        return [
          { key: 'id', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
          { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
          { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
          { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
          { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
          { key: 'zppractions', label: this.$i18n.t('labels.actions') },
        ]

      }
    },

    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'tag_by_id',
      'project_by_id',
      'username_by_id',
      'find_planning',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user'
    ]),
    ...mapState([
      'users',
      'current_user',
      'branches',
      'workfields',
      'avatars',
      'projects',
      'contracts',
      'hours',
      'companies',
      'planning',
    ]),

  },
  methods: {
    userstatus() {
      if(this.user) {
        let timediff = (this.find_last_planning_user(this.user.id) - new Date()) / (1000 * 60 * 60 * 24)
        let locuser = this.user_by_id(this.user.id)
        if (locuser && locuser.blacklist != undefined && locuser.blacklist == 1) {
          this.statusColor = 'background: #2c3e50';
          return this.$t("labels.statusblacklist")
        } else {
          if (timediff > 0 || timediff > -7) {
            this.statusColor = 'background: #69b06a; color: #fff;';
            return this.$t("labels.statusactive")
          }
          if (timediff < -7 && timediff > -90) {
            this.statusColor = 'background: #5499da; color: #fff;';
            return this.$t("labels.statusinactive")
          }
          if (timediff < -90 && timediff > -356) {
            this.statusColor = 'background: #f0EF9d';
            return this.$t("labels.statusinactive2")
          }
          if (timediff < -356 && timediff > -712) {
            this.statusColor = 'background: #f99d61; color: #fff;';
            return this.$t("labels.statusinactive3")
          }
          if (timediff < -712 && timediff > -7120) {
            this.statusColor = 'background: #EB6d6d; color: #fff;';
            return this.$t("labels.statusinactive4")
          }
          if (timediff < -7120) {
            this.statusColor = 'background: #aaaaaa; color: #fff;';
            return this.$t("labels.statusinactive5")
          }
        }
      }
      return "none";
    },
    companyInfo(item) {
      let res = ""
        res = "<b>" + this.company_by_id(item.company_id).company_name + "</b><br>"
        res += this.username_by_id(item.user_id) + "<br>"
        let user = this.user_by_id(item.user_id);
        if (user && user.mobile != undefined) {
          res += "<font-awesome-icon icon=\"mobile\" class=\"multi-append\"/> " + user.mobile + "<br>"
        }
      return res
    },

    onDocumentsClicked(row) {
      if(row.link) {
        let routeUrl = this.server_url + '/media_' + row.link;
        setTimeout(function () {
          window.open(routeUrl, '_blank')
        }, 500);
      }
    },
    saveuser() {
      this.save_user(this.user.id)
    },

    freeprojects() {
      // let public_projects = 0;
      let userinprojects = 0;
      let pf = this.projects.filter(p=> p.ispublic == 1)
      pf.forEach(p =>{
        if(p.zzprusers.indexOf(this.user_id)!=-1){
          userinprojects++
        }
      })
      return userinprojects < pf.length
    },
    show_friend(id) {
      this.$router.push({ path: '/user/' + id });
    },

    contact_user() {
      if (this.user.id == this.current_user.id) {
        this.$modal.hide('message_modal')
        this.$modal.show(message_modal, {
          title: "contact  ZZPR",
          message: "Bel 0413-379279"
        }, {
          name: 'message_modal',
          draggable: '.popup-header',
          height: 'auto',
          width: '90%'
        })
        this.$emit('close')
      } else {
        this.$modal.hide('contact_user_modal')
        this.$modal.show(contact_user_modal, {
          user_id: this.user.id,
        }, {
          name: 'contact_user_modal',
          draggable: '.popup-header',
          height: 'auto',
          width: '1000px'
        })
        this.$emit('close')
      }
    },
    msal_contact_user() {

        this.$modal.hide('msal_contact_user_modal')
        this.$modal.show(msal_contact_user_modal, {
          user_id: this.user.id,
        }, {
          name: 'msal_contact_user_modal',
          draggable: '.popup-header',
          height: 'auto',
          width: '1000px'
        })
        this.$emit('close')
    },    
    notify_user() {
      
      this.$modal.hide('notify_user_modal')
        this.$modal.show(notify_user_modal, {
          user_id: this.user.id,
        }, {
          name: 'notify_user_modal',
          draggable: '.popup-header',
          height: 'auto',
          width: '800px'
        })
        this.$emit('close')

    },
    weekplanning() {
      this.$bvModal.show('BSWeekPlanningID')
    },
    waxVacation() {
      this.$bvModal.show('BSWaxVacationID')

    },
    usermodalClose() {
      this.componentKey++
    },
    show_company(id) {
      this.$router.push({ path: '/company/' + id });
    },
    ...mapActions([
      'save_user',
      'add_user',
      'save_contract',
    ]),
    mailto(id) {
      return "mailto:" + id;
    },
    phoneto(id) {
      return "tel:" + id;
    },
    docto(id) {
      return this.server_url + '/media_' + id;
    },
    focusChange() {
      console.log("focuschanged")
    },
    saveMe(){
      console.log("saveMe")
      this.save_user(this.user_id)
    },  

    brancheName() {
      const branch = this.branche_by_id(this.user.branche_id);
      var ret = ""
      if (branch) {
        ret += "<b>" + branch.name + "</b><br>"
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == this.user.branche_id) {
            if (this.user.workfields.indexOf(this.workfields[i]["id"]) != -1) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }
      }
      return ret
    },
    sign_by_zzpr(contract) {
      this.contract_by_id(contract.id).signed_zzpr = 1
      this.save_contract(contract.id);
    },
    sign_by_company(contract) {
      this.contract_by_id(contract.id).signed_company = 1
      this.save_contract(contract.id);
    },

    hasbirthday() {
      if (this.user.birthday != null) {
        let birthDate = new Date(this.user.birthday)
        var today = new Date();
        let checkbd = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
        var diff = (checkbd.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if ((diff > -1 && diff < 7)) {
          return true
        }
      }
      return false
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      let expireDate = new Date(item.expires)
      var today = new Date();
      var diff = (expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
      if ((diff < 2)) {
        return 'status-expires'
      }
      if ((diff < 31)) {
        return 'status-expires-soon'
      }      
      return 'status-active'

    },
    append_data_multi(key) {
      var current = ""
      if (this.user[key] && this.user[key] != null) {
        current = "\n" + this.user[key]
      }
      this.user[key] = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9;
          })
        }
      }
    },
    company_contact() {
      if (this.createAny('users')) {
        if (this.user.company_contact == "1") {
          this.user.company_contact = "0"
        } else {
          this.user.company_contact = "1"
        }
        this.saveuser();
      }
    },
    document_contact() {
      if (this.createAny('users')) {
        if (this.user.document_contact == "1") {
          this.user.document_contact = "0"
        } else {
          this.user.document_contact = "1"
        }
        this.saveuser();
      }
    },
    invoice_contant() {
      if (this.createAny('users')) {
        if (this.user.invoice_contant == "1") {
          this.user.invoice_contant = "0"
        } else {
          this.user.invoice_contant = "1"
        }
        this.saveuser();
      }
    },
    sign_contact() {
      if (this.createAny('users')) {
        if (this.user.sign_contact == "1") {
          this.user.sign_contact = "0"
        } else {
          this.user.sign_contact = "1"
        }
        this.saveuser();
      }
    },
    location_contact() {
      if (this.createAny('users')) {
        if (this.user.location_contact == "1") {
          this.user.location_contact = "0"
        } else {
          this.user.location_contact = "1"
        }
        this.saveuser();
      }
    },
    sendSMSUser() {
      let sms = ""

      this.$modal.hide('send_sms_modal')
      this.$modal.show(send_sms_modal, {
        message: sms,
        user_id: this.user.id
      }, {
        name: 'send_sms_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '600px'
      })

      this.$emit('close')

    },    
    showCreditInvoice(){
      this.$bvModal.show('BSCreditInvoiceID')
    }

  },
  components: { AdminProjectsOverview, flatPickr,BSCreditInvoice},//,BSWeekPlanning,BSWaxVacation},
  watch: {
  }
}
</script>