<template>
  <div class="hello">
    <h2 class="modal-title">{{ data.userfullname }} {{ $t("buttons.reviewuser") }} </h2>
    <div v-html="page" />

<div v-if="show" >
</div>
<star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="rating"></star-rating>
<button v-if="rating>0" class="btn btn-primary" @click="close_and_save">{{ $t( "buttons.saveandclose") }}</button>

</div>
</template>

<script>
import axios from 'axios';
import { mapGetters} from 'vuex'

import StarRating from 'vue-star-rating'


export default {
  name: "HelloWorld",
  data() {
    return {
      code:null,
      data:null,
      show:false,
      settings:null,
      rating:0
    }
  },
  async mounted() {
    this.code = this.$route.params.id
    console.log("mount2", this.$route.params)
    await axios.get('/api/public_settings').then( async (response) =>{
      this.settings = response.data
    })
    await axios.get('/api/review_user/'+this.code)  .then( async (response) => {
      console.log('succes',response)
      this.data=response.data
      this.show= true
    })
  },
  computed:{
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'tag_by_id',
      'project_by_id',
      'username_by_id',
      'find_planning',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user'
    ]),
    page(){
      let ret = this.settings.reviewuser
      ret = ret.replaceAll(/\[signer\]/gi, this.data.signerfullname)
      ret = ret.replaceAll(/\[user\]/gi, this.data.userfullname)
      return ret
    }
  },
  methods: {
    close_and_save(){
      console.log("close_and_save")
      console.log(this.data.user_id, this.data.signer_id, this.rating)
    }
  },
  components:{StarRating}

};
</script>

