<template>
  <b-modal id="userHoursModalID" centered size="lg" @hide="hideMe" @show="showMe" >
    <template #modal-header style="font-weight: bold;" :style= "AppSecondaryColor()">
      {{title}}<br>
      {{daypart}} {{ username}}
      <div style="color: red;"  v-if="feestdag(dateToNL(day))!=''">{{ feestdag(dateToNL(day)) }}</div>
      <div class="popup-close" @click="cancel_edit">
                <font-awesome-icon icon="times" />
            </div>
    </template>
    <input type="hidden" v-model="componentKey" />
    <form ref="Top" class="form" :style= "AppSecondaryColor()">
      <div class="form-row" v-if="shownormalhours && (!settings.RegisterWorkhours || (userplanning && userplanning.workhours=='no'))">
        <div class="col-6">
          <label v-if="userplanning" for="" :style= "AppSecondaryColor()">{{ $t("labels.standardhours") }} (€ {{userplanning.tarief}} )</label>
        </div>
        <div class="col">
          <b-form-input :style= "AppSecondaryColor()" @blur="roundvalue()" type="number" class="form-control" step="0.25" min="0" max="10" v-model="normalhours">
          </b-form-input>
        </div>
      </div>
      <div class="form-row" v-if="shownormalhours && (!settings.RegisterWorkhours || (userplanning && userplanning.workhours=='no'))">
        <div class="col-6"></div>
        <div class="col">
          <p>{{ $t("templates.addhours") }}</p>
        </div>
      </div>

      <div v-if="userplanning &&(userplanning.urencodering == 'yes' ||userplanning.urencodering == 'both')" class="form-row">
        <div class="col-4">
          <label for="" :style= "AppSecondaryColor()" >{{ $t("labels.choose") }}</label>
        </div>
        <div class="col">
          <b-form-select :options="validhourcodes" class="contactpersoon" v-model="hourcode">
            
          </b-form-select>
        </div>
      </div>
      <div class="form-row" v-if="userplanning &&(userplanning.urencodering=='free'||userplanning.urencodering == 'both')">
        <div class="col-4">
          {{ userplanning.label1 }}
        </div>
        <div class="col">
          <b-form-input type=text class="form-control" v-model="free1" />
        </div>
      </div>
      <div class="form-row" v-if="userplanning &&(userplanning.urencodering=='free'||userplanning.urencodering == 'both')">
        <div class="col-4">
          {{ userplanning.label2 }}
        </div>
        <div class="col">
          <b-form-input type=text class="form-control" v-model="free2" />
        </div>
    </div>  
    <hr v-if="settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'">
    <div class="form-row" v-if="settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'">
        <div class="col-6">
          {{ $t('labels.workhoursstart') }}
        </div>
        <div class="col">
          <b-form-input type=time class="form-control" v-model="signature.workhoursstart" @change="calculateworkhours"/>
        </div>
    </div>  
    <div class="form-row" v-if="settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'">
        <div class="col-6">
          {{ $t('labels.workhoursend') }}
        </div>
        <div class="col">
          <b-form-input type=time class="form-control" v-model="signature.workhoursend" @change="calculateworkhours"/>
        </div>
    </div>  
    <div class="form-row" v-if="settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'" >
        <div class="col-6">
          {{ $t('labels.workhourspause') }}
        </div>
        <div class="col">
          <b-form-input type=number min="0" max="150" class="form-control" v-model="signature.workhourspause" @change="calculateworkhours"/>
        </div>
    </div>
    <div class="form-row" v-if="shownormalhours && settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'" >
        <div class="col-6">
          <label for="" :style= "AppSecondaryColor()">{{ $t("labels.standardhours") }}</label>
        </div>
        <div class="col">
          <b-form-input :style= "AppSecondaryColor()" disabled type="number" class="form-control" step="0.25" min="0" max="10" v-model="normalhours">
          </b-form-input>
        </div>
      </div>

      <div class="form-row">
        <div class="col-4">

          <div v-if="settings.SignWorkhours && userplanning&&(userplanning.signworkhours=='yes' && signature.id==0)" :style= "AppPrimaryButton()"  class="btn btn-primary" :disabled=checkfilled @click="signhours()">{{ $t("buttons.sign") }}</div>
      
          <a v-if="contract && hassignature(day, contract.user_id, contract_id)" href='javascript:;' class='action-item'
              @click="showwerkbriefje(day,contract.user_id, contract_id)"> <font-awesome-icon icon="eye" />{{ $t('buttons.signed') }}</a>
        </div>
        <div class="col">
            <div v-if="settings.SignWorkhours && userplanning &&(userplanning.signworkhours=='yes' && signature.id!=0)" :style= "AppPrimaryButton()"  class="btn btn-primary " @click="signhours()">{{ $t("buttons.signagain") }}</div>
        </div>
      </div>
    <hr v-if="hasOnregelmatig">
    <div v-if="contract">
      <div class="form-row" v-for="(item, index)  in contract.onregelmatig" :key="`onregel-${item.id}`">
        <div class="col-6" v-if="item.active == 1">
          <label v-if="declaratie_by_id(item.declaratie_id).type!='total'" :style= "AppSecondaryColor()">{{ declaratie_by_id(item.declaratie_id).name }}  (€ {{item.zzpr_amount  }})</label>
          <label v-else :style= "AppSecondaryColor()">{{ declaratie_by_id(item.declaratie_id).name }} </label>
        </div>
        <div class="col" v-if="item.active == 1">
          <b-form-input v-model="item.hours" :style= "AppSecondaryColor()" :state="checkOnregelmatig(item)" placeholder="0" type="number" class="form-control" step="0.25" @input="updateOnregelmatig($event, index)"></b-form-input>
        </div>
      </div>
    </div>
      <div v-if="createAny('users')" class="form-row">
        <div class="col-4">
          <label for="">{{ $t("labels.note") }}</label>
        </div>
        <div class="col">
          <b-input-group v-if="createAny('users')">
            <b-form-textarea size="sm" rows="8" v-bind:type="comment" v-bind:id="'bind-remarks'" ref="comment"
              class="form-control" v-model="comment" />
            <b-input-group-append>
              <b-button squared class="multi-append"  href="javascript:;"
                @click="append_data_multi('comment')">
                <font-awesome-icon icon="plus-circle" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </form>
    <div :style= "AppSecondaryButton()" v-if="hourdata(day).length>0" class="btn btn-secondary" @click="save_and_close()">{{ checkvalid() }}</div>
    <hr v-if="hourdata(day).length>0">
    <b-button :style= "AppPrimaryButton()" v-if="hour_id != 0 && (!settings.RegisterWorkhours || (userplanning && userplanning.workhours=='no'))" variant="primary" @click="new_hour()" >
      <font-awesome-icon icon="plus" /> {{ $t("labels.addhours") }}
    </b-button>
    <div v-for="(ahourdata, index) in hourdata(day)" :key="'somehours' + index">
      <FilledHours v-if="ahourdata.hours != 0 || ahourdata.declaraties" @clickHour="hoursClick" :showEdit=true :hourdata="ahourdata" />
    </div>
    <BSSignWorkday :signature="signature" :normalhours="parseFloat(normalhours)" :showhours="settings.RegisterWorkhours && userplanning && userplanning.workhours=='yes'" />

    <template #modal-footer>
      <div :style= "AppPrimaryButton()"  class="btn btn-primary" @click="save_and_close()">{{ checkvalid() }}</div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FilledHours from '../FilledHours.vue'
import BSSignWorkday from './BSSignWorkday.vue'
import axios from 'axios'

export default {
  name: 'user-add-hours-modal',
  data() {
    return {
      normalhours: null,
      hourcode: null,
      hour_id: 0,
      comment: null,
      componentKey: 0,
      free1:"",
      free2:"",
      new:false,
      genhourdata:[],
      contract_id: Number,
      day: String,
      select:Boolean,
      onregelmatigFill:true

    }
  },
  // props: {
  //   contract_id: Number,
  //   day: String,
  //   select:Boolean
  // },
  mounted() {
    this.contract_id = this.userhourmodal_settings.contract_id
    this.day  = this.userhourmodal_settings.day
    this.select = this.userhourmodal_settings.select

  },
  computed: {
    contract() {
      return this.contract_id?this.contract_by_id(this.contract_id):null
    },
    checkfilled(){
      return this.normalhours===null// (this.signature.workhoursstart==null || this.signature.workhoursend==null) || this.normalhours!=0
    },
    onregelmatigFilled(){
      return this.onregelmatigFill
      // var isfilled=false;
      // if(this.contract){
      // for(var i in this.contract.onregelmatig){
      //   console.log(this.contract.onregelmatig[i])
      //   if(this.contract.onregelmatig[i].active==1){
      //     console.log(this.contract.onregelmatig[i].declaratie_id,this.contract.onregelmatig[i].hours)
      //     if(this.contract.onregelmatig[i].hours!==null){
      //       isfilled=true
      //     }
      //   }
      // }
    // }
    //   return isfilled
    },
    signature(){
      // console.log("findsignature", this.signatures.length)
      if(this.contract){
        let local_signature = this.signatures.filter(s=> s.workdate==this.day && s.user_id==this.contract_by_id(this.contract_id).user_id && s.contract_id==this.contract_id)
        // console.log("signatureslength")
        if(local_signature&&local_signature.length>0){
          return local_signature[0]
        } else {
          return this.workhourcheck_new
        }
      }
      return null
    },
    userplanning() {
      return this.contract?this.planning_by_id(this.contract.planning_id):null
    },
    hasOnregelmatig(){
      // console.log("hasOnregelmatiglength")

      return this.contract?this.contract.onregelmatig.filter(o =>o.active==1).length>0:false
    },
    validhourcodes() {
      let validcodes = []
      validcodes.push({ value: null, text: this.$t('labels.selecthourcode') })
      // console.log("hourcodeslength")

      for (var jj = 0; jj < this.hourcodes.length; jj++) {
        if (this.hourcodes[jj].hour_category_id === this.userplanning.hourcategory && this.hourcodes[jj].active == 1) {
          if (this.hourcodes[jj].parent == -1) {
            let text =this.hourcodes[jj].code?this.hourcodes[jj].code+" - ":"";

            validcodes.push({ value: this.hourcodes[jj].id, text: text + this.hourcodes[jj].name })
          } else {
            let parent = this.hourcode_by_id(this.hourcodes[jj].parent);
            let text =this.hourcodes[jj].code?this.hourcodes[jj].code+" - ":"";
            let parenttext = parent.code?parent.code + ".":""
            validcodes.push({ value: this.hourcodes[jj].id, text: parenttext + text +  "[" + parent.name + "]-" + this.hourcodes[jj].name })
          }
        }
      }
      return validcodes
    },
    title() {
      return this.userplanning.project_name 
    },
    daypart() {
      return this.dayOfWeek(this.day) +" "+this.dateToNL(this.day)
    },
    username(){
      return this.contract?this.username_by_id(this.contract_by_id(this.contract_id).user_id) :""
    },
    shownormalhours(){
      if(this.contract && this.contract.registernormalhours!=undefined && this.contract.registernormalhours=='no'){
        return false
      } 
      return true
    },
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'workhourcheck_new',
      'contract_by_id',
      'username_by_id',
      'planning_by_id',
      'declaratie_by_id',
      'hours_by_id',
      'createAny',
      'append_data_string',
      'hourcode_by_id',
      'signature_by_id'
    ]),
    ...mapState([
      'projects',
      'settings',
      'companies',
      'declaraties',
      'hourcodes',
      'hourcategories',
      'hours',
      'signatures',
      'userhourmodal_settings'
    ])
  },
  methods: {
    signhours(){
      this.$bvModal.show('BSSignWorkdayID')
      
    },
    // fillnormalhours(h){
    //   this.normalhours = h
    // },
    // fillhourworks(s,e){
    //   this.signature.workhoursstart= s
    //   this.signature.workhoursend = e

    // },
    hideMe(){
      this.new = false
      this.hour_id = 0
      this.normalhours = null
      this.hourcode = null
      this.free1 = ""
      this.free2 = ""  
      this.genhourdata = [] 
      this.comment=""
      this.$store.commit('new_workhourcheck', {  id: 0,workhoursstart:null,workhoursend:null, workhourspause:0, signed_by:"" })

    },
    showMe(){
      this.contract_id = this.userhourmodal_settings.contract_id
      this.day  = this.userhourmodal_settings.day
      this.select = this.userhourmodal_settings.select
      this.new = false
      this.hour_id = 0
      this.normalhours = null
      if(this.contract.registernormalhours=="no"){
        this.onregelmatigFill = false
      }
      if(!this.shownormalhours){
        this.normalhours = 0
      }
      this.hourcode = null
      this.free1 = ""
      this.free2 = ""   
      this.contract.onregelmatig.forEach(element => {
        element.hours = null
      });    
      this.comment=""
      this.$nextTick(() => {
        if(this.userhourmodal_settings.fromLayer==true){
          this.normalhours = parseFloat(this.userhourmodal_settings.normalhours)
          this.signature.workhoursstart=  this.userhourmodal_settings.workhoursstart
          this.signature.workhoursend =  this.userhourmodal_settings.workhoursend
        }
        this.signature.workdate=this.day
        this.signature.user_id = this.contract?this.contract_by_id(this.contract_id).user_id:-1
        this.signature.contract_id= this.contract_id
        this.componentKey++
      })

    
      if(this.settings.RegisterWorkhours && this.userplanning && this.userplanning.workhours=='yes'){
        this.$nextTick(()=>{
          this.calculateworkhours()

        })
      }

    },
    append_data_multi(key) {

      var current = ""
      if (this.comment) {
        current = "\n" + this.comment
      }
      this.comment = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9
          })
        }
      }
    },
    validated() {
      var hourcodecheck = true
      if(!this.userplanning||!this.contract){
        return false
      }
      if (this.userplanning.urencodering == 'yes') {
        hourcodecheck = (this.hourcode != 0 && this.hourcode != null)
      }
      if (this.userplanning.urencodering == 'free') {
        hourcodecheck = this.free1!="" && this.free2!=""
      }     
      if (this.userplanning.urencodering == 'both') {
        hourcodecheck = this.free1!="" && this.free2!="" &&(this.hourcode != 0 && this.hourcode != null)
      }            
      var checkcomment = true
      // if (this.createAny('users')) {
      //   checkcomment = this.comment != "" && this.comment != null
      // }
      let signaturecheck = this.settings.RegisterWorkhours==false || this.userplanning.signworkhours=='no'||this.userplanning.signworkhours==null ||(this.userplanning.signworkhours=='yes' && this.signature.id!=0)
      console.log(this.onregelmatigFilled ,this.normalhours != null , !isNaN(this.normalhours) , hourcodecheck , checkcomment , signaturecheck)
      return (this.onregelmatigFilled &&this.normalhours != null && !isNaN(this.normalhours) && hourcodecheck && checkcomment && signaturecheck)
    },

    checkvalid() {
      if (this.validated()) {
        if (this.hour_id == 0) {
          return this.$i18n.t("buttons.add")
        } else {
          return this.$i18n.t("buttons.saveandclose")
        }
      } else {
        return this.$i18n.t("buttons.invalid")
      }
    },
    new_hour() {
      this.hour_id = 0
      this.normalhours = null
      if(!this.shownormalhours){
        this.normalhours = 0
      }      
      this.hourcode = null
      this.new = true
      this.free1 = ""
      this.free2 = ""
      this.contract.onregelmatig.forEach(element => {
        element.hours = 0
      });
      // console.log(">ONREGEL",this.contract.onregelmatig)
      this.onregelmatigFill= true

      this.comment=""
    },
    hoursClick(anhour) {
      let findhourCode = this.hourcodes.filter(hc => {
        return hc.code == anhour.project
      })
      if (findhourCode.length > 0) {
        this.hourcode = findhourCode[0].id
      }
      this.hourcode = anhour.project
      this.hour_id = anhour.id
      this.comment = anhour.comment
      let onregelitem
      anhour.declaraties.forEach(or => {
        onregelitem = this.declaraties.filter(obj => {
          return obj.name === or.label
        })
        let contractitem = this.contract.onregelmatig.filter(adec => {
          return adec.declaratie_id === onregelitem[0].id
        })
        if (contractitem) {
          contractitem[0].hours = or.value
          if(or.value!=null && or.value!=0){
            this.onregelmatigFill= true
          }
        }
      });
      this.normalhours = parseFloat(anhour.hours)
      this.free1 = anhour.free1
      this.free2 = anhour.free2
      this.new = false
      if(this.$refs["Top"]) {
        this.$refs["Top"].scrollIntoView({ behavior: "smooth" })
      }

    },

    hourdata(item) {
      // if(this.genhourdata.length>0){
      //   return this.genhourdata
      // } else {
        if(!this.contract){
          return []
        }
        let returnhours = [];
        for (let ahour of this.hours.filter(obj => {
          return obj.user_id === this.contract.user_id
        })) {
          if (ahour.contract_id == this.contract_id && ahour.workdate == item) {
            let thishour = {}
            thishour.id = ahour.id
            thishour.comment = ahour.comment
            thishour.hours = ahour.hours
            thishour.free1 = ahour.free1
            thishour.free2 = ahour.free2
            thishour.contract_id = ahour.contract_id

            thishour.workdate = ahour.workdate
            if (ahour.hourcode) {
              thishour.project = ahour.hourcode//this.hourcode_by_id(ahour.hourcode)?this.hourcode_by_id(ahour.hourcode).id:0
            }
            thishour.hourcode = ahour.hourcode

            //build declaraties
            let declaraties = []
            let contract = this.contract_by_id(ahour.contract_id)

            for (const onregel of contract.onregelmatig) {
              let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
              if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                declaraties.push({ "label": declaratie_cat.name, "value": ahour[declaratie_cat.name] });
              }
            }
            thishour.declaraties = declaraties
            returnhours.push(thishour)
          }
        }
        // console.log("returnhourslength")

        if (returnhours.length > 0) {
          //if 1 hourdata filled open this data
          if(this.hour_id==0 && !this.new && (this.select||(this.settings.SignWorkhours && this.userplanning&&this.userplanning.signworkhours=='yes'))){
            this.hoursClick(returnhours[0])
          }
          this.genhourdata = returnhours
          return returnhours;
        } 
        return []
      // }
    },
    getNumbers: function (start, stop, step = 1) {
      return new Array(stop / step).fill(start).map((n, i) => (i + 1) * step);
    },
    save_and_close() {
      if (!this.validated()) {
        return
      }
      if (this.hour_id == 0) {
        var newhours = {
          id: this.hour_id,
          user_id: this.contract.user_id,
          contract_id: this.contract_id,
          workdate: this.day,
          hours: this.normalhours,
          added_date: new Date().toUTCString(),
          hourcode: this.hourcode,
          comment: this.comment,
          free1: this.free1,
          free2: this.free2
        }
        for (const item of this.contract.onregelmatig) {
          if (item.active == 1) {
            newhours[this.declaratie_by_id(item.declaratie_id).name] = item.hours==null?0:item.hours
          }
          item.hours = null;
        }
        this.add_hours(newhours)
      } else {
        let thishour = this.hours_by_id(this.hour_id)
        thishour.hours = this.normalhours
        thishour.hourcode = this.hourcode
        for (const item of this.contract.onregelmatig) {
          if (item.active == 1) {
            thishour[this.declaratie_by_id(item.declaratie_id).name] = item.hours
          }
          item.hours = null;
        }
        thishour.comment = this.comment
        thishour.free1 = this.free1
        thishour.free2 = this.free2
        this.save_hours(this.hour_id)
      }
      // save signatues when signing is not required!
      if(this.settings.RegisterWorkhours && this.userplanning.workhours=='yes'&&this.userplanning.signworkhours && this.userplanning.signworkhours!='yes'){
        let clone = Object.assign({}, this.signature)
        clone.data = null
        clone.added_date = new Date().toUTCString()
        clone.signed_by = this.signed_by
        axios.put('/api/workhourscheck/'+clone.id, clone)
        this.$store.commit('new_workhourcheck', {  id: 0,workhoursstart:null,workhoursend:null, workhourspause:0, signed_by:"" })
        this.$store.dispatch('get_signatures')
      }
      this.normalhours = null
      this.hourcode = 0
      this.free1 = ""
      this.free2 = ""
      this.$bvModal.hide('userHoursModalID')
    },
    ...mapActions([
      'save_hours',
      'add_hours'
    ]),
    roundvalue(){
      let decimal = (Math.round(this.normalhours * 4) / 4).toFixed(2)
      this.normalhours = decimal.split('.')[0] + '.' + decimal.split('.')[1].slice(0, 2)
    },
    checkOnregelmatig(item) {
      return item.hours !=null
    },

    updateOnregelmatig($event, index){
      console.log("üpdate",$event, index)
      this.onregelmatigFill = true
      this.contract.onregelmatig[index].hours = $event;
      this.componentKey++
    },
    calculateworkhours(){
      // console.log("go")
      let start = new Date()
      let end = new Date()
      if((this.signature.workhoursstart!=null && this.signature.workhoursend!=null)){
        start.setHours(this.signature.workhoursstart.split(":")[0])
        start.setMinutes(this.signature.workhoursstart.split(":")[1])
        end.setHours(this.signature.workhoursend.split(":")[0])
        end.setMinutes(this.signature.workhoursend.split(":")[1])
        var  workhours = end-start
        workhours -= this.signature.workhourspause * (60*1000)
        this.normalhours = (workhours/(60*60*1000)).toFixed(2)
        if(!this.shownormalhours){
          this.normalhours = 0
        }
        this.roundvalue()
      }
      
    },
    cancel_edit(){
      this.$bvModal.hide('userHoursModalID')

    }

  },
  components: { FilledHours ,BSSignWorkday},
  watch:{
    day(){
      this.genhourdata = []
    },
    signatures(){
      this.componentKey++
    }
  }
}
</script>