<template>

  <b-container style="margin-top:20px" fluid :style= "AppSecondaryColor()"
    v-if="createAny('users') || contract.user_id == current_user.id || userplanning.sign_id == current_user.id">
    <b-button :style="AppPrimaryButton()" variant="primary" @click="backToUser()">
        <font-awesome-icon icon="arrow-left" />{{ $t("labels.backto") }} {{ $t("titles.tasks") }}
      </b-button>
    <div  class="public_project" :style= "projectTitleAppClass()">
      <p :style="AppPrimaryColor()" class="title">{{ title }}</p>
      <b-row>
        <b-col sm="6">
          <dl>
            <dt>{{ $t("meta.projectnumber") }} </dt>
            <dd>{{ planning_by_id(contract.planning_id).project_reference_number}}</dd>
            <dt>{{ $t("labels.client") }} </dt>
            <dd>{{ companyName}}</dd>          
            <dt v-if="current_user.role=='user'">{{ $t("labels.contactperson") }}</dt>
            <dt v-else>{{ $t("labels.byzzpr") }}</dt>
            <dd v-if="current_user.role=='user'">{{ username_by_id(userplanning.pointcontact_id) }}</dd>
            <dd v-else>{{ username_by_id(userplanning.user_id) }}</dd>
            <dt>{{ $t("labels.week") }}</dt>
            <dd>{{ weeknumber }}</dd>
            <dt>{{ $t("labels.duration") }}</dt>
            <dd>{{ new Date(userplanning.startdate).toLocaleDateString() }} {{ $t("labels.to") }} {{ new
            Date(userplanning.enddate).toLocaleDateString()
            }}</dd>
          </dl>
        </b-col>
      </b-row>
  </div>
  <div v-if="false" class="btn btn-primary" @click="showAbsent">{{$t('labels.setabsent')}}</div>
    <div id="day" v-for="(tditem, index) in contractinfo.dates[0]" :key="index" :style="{ display: tditem == 0?'none':'block' }">
      <div v-if="tditem != 0">

        <div class="title">
          <h3 :style= "AppSecondaryColor()">
            {{ dayOfWeek(tditem) }} {{ dateToNL(tditem) }}
          </h3>
          <h5 style="color: red;" v-if="feestdag(dateToNL(tditem))!=''"> {{ feestdag(dateToNL(tditem)) }}</h5>

          <b-button :style= "AppSecondaryButton()" variant="secondary" v-if="dateActive(tditem) && !isclosed(tditem) && !issigned(tditem)"
            href='javascript:;' @click="addhours(tditem)">
            <span>
              <font-awesome-icon icon="plus" /> {{ newhourslabel }} 
            </span>
          </b-button>
          <b-button :style= "AppSecondaryButton()" variant="secondary" v-if="hasdata(tditem) && dateActive(tditem) && !isclosed(tditem) && !issigned(tditem)"
            href='javascript:;' @click="fillhours(tditem)">
            <span v-if="hasdata(tditem)">
              <font-awesome-icon icon="pen" /> {{ edithourslabel }}
            </span>
            <span v-else>
              <font-awesome-icon icon="plus" /> {{ newhourslabel}}
            </span>
          </b-button>
        </div>

        <div v-if="isclosed(tditem)">
          <p v-if="current_user.role == 'user'">{{ $t('labels.weekclosed') }}</p>
        </div>
        <div v-if="false && !dateActive(tditem)">{{ dateToNL(tditem) }}</div>
        <div v-if="hasdata(tditem) == true">
          <div v-for="(ahourdata, index) in hourdata(tditem)" :key="'somehours' + index">
            <FilledHours v-if="ahourdata.hours != 0||ahourdata.declaraties" :hourdata="ahourdata"></FilledHours>
            <b-button :style= "AppSecondaryButton()" v-if="hassignature(tditem, userplanning.user_id, contract_id)" href='javascript:;' class='btn btn-primary'
                    @click="showwerkbriefje(tditem, userplanning.user_id, contract_id)"> <font-awesome-icon icon="eye" /> {{ $t('buttons.signed') }}</b-button>   
            <b-button :style= "AppSecondaryButton()" v-if="contract_by_id(contract_id).workhours !='yes' &&  hasonlysignature(tditem, userplanning.user_id, contract_id)" href='javascript:;' class='action-item'
                    @click="showsignature(tditem, userplanning.user_id, contract_id)"> <font-awesome-icon icon="signature" />{{signatures.filter(s=>s.user_id==userplanning.user_id&&s.workdate==tditem&& s.contract_id==contract_id)[0].signed_by}}</b-button>                              
          </div>
        </div>
        <div v-if="needs_day_attachment(tditem)">
          <UrenBonnen :contract_id="contract_id" :week_number="weeknumber" :year_number="yearnumber"
          :isclosed="week_is_closed()" :date="tditem"/>
          <div v-if="current_user.role=='user'" href='javascript:;' class="btn btn-secondary" :style= "AppSecondaryButton()" @click="upload_dayurenbon(tditem)"
            :title="$t('buttons.addurenbon')">
            <font-awesome-icon icon="upload" />{{ $t("buttons.addurenbon") }}
          </div>
        </div>
      </div>
    </div>

    <b-row>
      <b-col>
        <UrenBonnen :contract_id="contract_id" :week_number="weeknumber" :year_number="yearnumber"
          :isclosed="week_is_closed()"/>
        <div v-if="!week_is_closed()" id="declarations">
          <em v-if="canuploadurenbon">{{ $t("labels.urenbonhelp") }}</em>
          <div href='javascript:;' class="btn btn-secondary" :style= "AppSecondaryButton()" @click="upload_urenbon()"
            :title="$t('buttons.addurenbon')">
            <font-awesome-icon icon="upload" />{{ $t("buttons.addurenbon") }}
          </div>
        </div>
        <!-- <div v-else>
          <div v-if="current_user.role != 'user'">
            <b-button  :style= "AppPrimaryButton()" v-if="has_open_hours() && !signing" variant="primary" @click="sign_week()">{{ $t("buttons.hoursvalidated") }}
            </b-button><br>
            <div v-if="has_open_hours()">{{ settings.check_hours_incorrect }}</div>
          </div>
        </div> -->
      </b-col>
      </b-row>
      <b-row v-if="current_user.role != 'user'">
        <b-col>
        <div v-if="current_user.role != 'user'">
            <b-button v-if="has_open_hours() && !signing" variant="primary" @click="sign_week()">{{ $t("buttons.hoursvalidated") }}
            </b-button><br>
            <div v-if="has_open_hours()">{{ settings.check_hours_incorrect }}</div>
          </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 :style= "AppSecondaryColor()"> {{ $t('labels.week') }} {{ weeknumber }} {{ yearnumber }}</h2>

        <dl id="week-overview" v-html='weektotal()'></dl>

      </b-col>
    </b-row>
    <div id="close-week" v-if="current_user.role == 'user' && !week_is_closed()">
      <b-button :style= "AppPrimaryButton()" variant="primary" @click="close_week()">
        <font-awesome-icon icon="lock" /> {{ $t("buttons.closeweek") }}
      </b-button>
    </div>
    <div style="height:5rem !important"></div>
    <!-- <UserHoursModal :day="showday" :contract_id="contract.id" :select="selecthours"  /> -->
    <UserHoursModal />
    <BSUploadUserModal :contract_id="contract_id" :weeknumber="weeknumber" :yearnumber="yearnumber" :internal=false :date="selectedday" :companyattachment=false />
    <BSAbsentModal :contract_id="contract_id" :weeknumber="weeknumber" :yearnumber="yearnumber" />

  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FilledHours from './FilledHours.vue'
import UserHoursModal from './modals/UserHoursModal'
import BSUploadUserModal from './modals/BSUploadUrenModal.vue'
import BSAbsentModal from './modals/BSAbsentModal.vue'

import UrenBonnen from './parts/ActiveUrenbonnen.vue'
import popup_modal from './modals/PopupModal.vue'
import popup_confirm from './modals/PopupConfirm.vue'
import axios from 'axios'
import dayjs from 'dayjs'
import { weeksPerYear } from 'weeknumber'


export default {
  name: 'user-check-hours-page',
  data() {
    return {
      normalhours: null,
      hourcode: 0,
      hour_id: 0,
      comment: null,
      contract_id: 0,
      weeknumber: 0,
      yearnumber: 0,
      showday: "",
      signing:false,
      selectedday:"",
      selecthours:true

    }
  },
  beforeMount() {
    this.contract_id = parseInt(this.$route.params.id)
    this.weeknumber = parseInt(this.$route.params.week)
    this.yearnumber = parseInt(this.$route.params.year)
    // if(this.settings.SendInvoiceOnChecked){
    //       console.log("JAJA", this.settings.purchasecc)
    //     } 
  },
  computed: {
    overviewconstruct(){
      return this.weeknumber.toString()+"-"+this.yearnumber.toString()
    },
    contract() {
      return this.contract_by_id(this.contract_id)
    },
    userplanning() {
      return this.planning_by_id(this.contract.planning_id)
    },
    title() {
      return (this.contract && this.userplanning) ? this.userplanning.project_name + " " + this.$i18n.t('labels.week') + " " + this.weeknumber + " " + this.yearnumber : "title"
    },
    shownormalhours(){
      if(this.contract && this.contract.registernormalhours!=undefined && this.contract.registernormalhours=='no'){
        return false
      } 
      return true
    },
    newhourslabel(){
      var r = this.$i18n.t('labels.addhours')
      if(!this.shownormalhours){
        r = this.$i18n.t('buttons.add')
      } 
      return r

    },
    edithourslabel(){
      var r = this.$i18n.t('labels.edithours')
      if(!this.shownormalhours){
        r =this.$i18n.t('labels.edit')
      } 
      return r
    },

    canuploadurenbon(){
      let ret = false
      this.contract.onregelmatig.forEach(onregelmatig =>{
        if(!ret && onregelmatig.active==1){
          ret = this.declaraties.filter(d=>d.id==onregelmatig.declaratie_id)[0].type =="total"
        }
      })
      return ret

    },

    startweek() {
      let sweek = this.getWeekNumber(new Date(this.userplanning.startdate))
      return sweek[1]
    },
    startyear() {
      let sweek = this.getWeekNumber(new Date(this.userplanning.startdate))
      return sweek[0]
    },
    endweek() {
      let sweek = this.getWeekNumber(new Date(this.userplanning.enddate))
      return sweek[1]
    },
    endyear() {
      let sweek = this.getWeekNumber(new Date(this.userplanning.enddate))
      return sweek[0]
    },
    companyName() {
      let project = this.project_by_id(this.contract.project_id)
      if (project) {
        return this.company_by_id(project.company_id).company_name
      }
      return ""
    },
    contractinfo() {
      let row = {}
      if (this.contract) {
        let project = this.project_by_id(this.contract.project_id)
        if (project) {
          row.project = this.userplanning.project_name
          row.contract_id = this.contract.id
          row.user_id = this.contract.user_id
          row.startdate = this.userplanning.startdate
          row.enddate = this.userplanning.enddate
          row.dates = []
          let contractstart = new Date(row.startdate);
          let contractend = new Date(row.enddate);
            let startdate = new Date(row.startdate);
            let enddate = new Date(row.enddate);
            let alldates = []
            let now = new Date()
            if(now>enddate){
              now = enddate
            }
            const start = dayjs(now)
            let localweek = start.diff(startdate, 'week')
            localweek = this.weeknumber -this.getWeekNumber(startdate)[1];
            if(localweek<0){
              //fix year leap
              let yearstart = this.getWeekNumber(startdate)[0]
              let weeksthisyear = weeksPerYear(parseInt(yearstart))
              localweek = weeksthisyear-this.getWeekNumber(startdate)[1]+this.weeknumber

            }
            for(var i=1;i<8;i++){
              let loopday = dayjs(row.startdate).add(localweek,'week').isoWeekday(i).format('YYYY-MM-DD')
              // console.log(loopday, row.startdate, localweek)
              let loop = new Date(loopday)
              if (loop.setHours(0,0,0) < contractstart.setHours(0,0,0) || loop.setHours(0,0,0) > contractend.setHours(0,0,0)) {
                alldates.push("0")
              } else {
                alldates.push(loopday)
              }            
            }


            while (alldates.length > 0) {
              row.dates.push(alldates.splice(0, 7))
            }
            if (row.dates.length > 0) {
              while (row.dates[row.dates.length - 1].length < 7) {
                row.dates[row.dates.length - 1].push("0")
              }
            }
        }
      }
      return row

    },
    invoiceextraweek() {
      return this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.weeknumber && i.year == this.yearnumber)

    },
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'project_new',
      'contract_by_id',
      'username_by_id',
      'user_by_id',
      'planning_by_id',
      'declaratie_by_id',
      'hours_by_id',
      'createAny',
      'append_data_string',
      'company_by_id',
      'hourcode_by_id',
      'confirm_modal'
    ]),
    ...mapState([
      'projects',
      'settings',
      'companies',
      'declaraties',
      'hourcodes',
      'hourcategories',
      'hours',
      'planning',
      'current_user',
      'urenbonnen',
      'facturen',
      'signatures',
      'invoiceextra'

    ])
  },
  methods: {

    ...mapActions([
      'save_hours',
      'add_hours',
      'close_hours',
      'agree_hours',
      'add_factuur',
      'add_pm',
      'get_facturen'


    ]),
    dateActive(item) {
      var now = new Date();
      var itemDate = new Date(item);
      return now > itemDate
    },
    issigned(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract.user_id) {
          return ahour.agree_company == 1
        }
      }
      return false
    },
    isclosed(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract.user_id) {
          return ahour.close_hours == 1
        }
      }
      return false
    },
    hasdata(item) {
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract.user_id) {
          return true
        }
      }
      return false
    },
    hourdata(item) {
      var returnhours = [];
      for (var ahour of this.hours) {
        if (ahour.contract_id == this.contract_id && ahour.workdate == item && ahour.user_id == this.contract.user_id) {
          let thishour = {}
          thishour.id = ahour.id
          thishour.comment = ahour.comment
          thishour.contract_id = ahour.contract_id
          thishour.hours = ahour.hours
          thishour.workdate = ahour.workdate
          thishour.agree_company = ahour.agree_company
          thishour.free1 = ahour.free1
          thishour.free2 = ahour.free2
          thishour.close_hours = ahour.close_hours
          if (ahour.hourcode) {
            thishour.project = ahour.hourcode//this.hourcode_by_id(ahour.hourcode)?this.hourcode_by_id(ahour.hourcode).code:0
          }
          thishour.hourcode = ahour.hourcode
          //build declaraties
          let declaraties = []
          let contract = this.contract_by_id(ahour.contract_id)

          for (const onregel of contract.onregelmatig) {
            let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
            if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
              declaraties.push({ "label": declaratie_cat.name, "value": ahour[declaratie_cat.name] });
            }
          }
          thishour.declaraties = declaraties
            returnhours.push(thishour)
        }
      }
      if (returnhours.length > 0) {
        return returnhours;
      }
      return false
    },
    addhours(day) {
      // find contract for project + user
      this.selecthours = false
      this.showday = day
      this.$store.commit('userhourmodal_settings',{contract_id:this.contract_id, day:this.showday, select:false,fromLayer:false})

      this.$bvModal.show('userHoursModalID')
    },
    fillhours(day) {
      // find contract for project + user
      this.selecthours = true
      this.showday = day
      this.$store.commit('userhourmodal_settings',{contract_id:this.contract_id, day:this.showday, select:true,fromLayer:false})

      this.$bvModal.show('userHoursModalID')
    },    
    backToUser() {
      if(this.week_is_closed()){
        if(this.has_open_hours()){
          this.$router.push({ path: '/tasks/2' })
        } else {
          this.$router.push({ path: '/tasks/3' })
        }
      } else {
        this.$router.push({ path: '/tasks/1' })
      }
    },
    weektotal() {
      var totalnormal = 0
      var declaraties = new Object()
      var prices = new Object()
      if (this.contractinfo.dates && this.contractinfo.dates[0]) {
        for (var adate of this.contractinfo.dates[0]) {
          for (var ahour of this.hours) {
            if (ahour.contract_id == this.contract_id && ahour.workdate == adate && ahour.user_id == this.contract.user_id) {
              if(ahour.hours) {
                totalnormal += parseFloat(ahour.hours)
              }

              let contract = this.contract_by_id(ahour.contract_id)

              for (const onregel of contract.onregelmatig) {
                let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                prices[declaratie_cat.name] = this.current_user.role!='user'?this.contract.onregelmatig.filter(on => on.declaratie_id == declaratie_cat.id)[0].client_amount:this.contract.onregelmatig.filter(on => on.declaratie_id == declaratie_cat.id)[0].zzpr_amount
                if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                  if (declaratie_cat.name in declaraties) {
                    if(ahour[declaratie_cat.name]) {
                      declaraties[declaratie_cat.name].total += parseFloat(ahour[declaratie_cat.name])
                    }
                  } else {
                    if(ahour[declaratie_cat.name]) {
                      declaraties[declaratie_cat.name] = {}
                      declaraties[declaratie_cat.name].total = parseFloat(ahour[declaratie_cat.name])
                      declaraties[declaratie_cat.name].type = declaratie_cat.type

                    }

                  }
                }
              }
            }
          }
        }
      }
      var retstring = ""
      let price = this.current_user.role!='user'?parseFloat(this.userplanning.verkooptarief):parseFloat(this.userplanning.tarief)
      if(this.shownormalhours){
        retstring = "<dt>" + this.$i18n.t('labels.total') + " " +  this.$i18n.t("titles.project_hours") + "</dt><dd> " + totalnormal +" ( € "+totalnormal*price+")" + "</dd>"
      }
      for (var k in declaraties) {
        console.log(">>>>>>",declaraties[k],k)
        if(parseFloat(declaraties[k].total )>0){
          if(declaraties[k].type !='total'){
            retstring += "<dt>" +this.$i18n.t('labels.total') + " " +  k + "</dt><dd>" + declaraties[k].total +" ( € "+prices[k]*declaraties[k].total+")" + "</dd>"

          } else {
            retstring += "<dt>" +this.$i18n.t('labels.total') + " " +  k + "</dt><dd>€ "+declaraties[k].total+"</dd>"

          }

        }
      }
      return retstring
    },
    week_is_closed() {
      var dates = this.contractinfo.dates
      var isclosed = false
      if (dates[0]) {
        dates[0].forEach(hourdates => {
          this.hours.forEach(ahour => {
            if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && hourdates != '0' && ahour.user_id == this.contract.user_id) {
              if (!isclosed && ahour.close_hours == 1) {
                isclosed = true
              }
            }

          });

        });
      }
      return isclosed
    },
    upload_urenbon() {
      this.selectedday=""
      this.$bvModal.show('BSUploadUrenModalID')
    },
    upload_dayurenbon(day) {
      this.selectedday=day
      this.$bvModal.show('BSUploadUrenModalID')
    },    
    needs_attachment() {
      var result = false
      var attchments = this.urenbonnen.filter(obj => {
        return obj.contract_id === this.contract_id
      }).filter(obj2 => {
        return obj2.week === this.weeknumber
      })
      if (attchments.length > 0) {
        return result
      }

      this.contract.onregelmatig.forEach(onregel => {
        var dec = this.declaratie_by_id(onregel.declaratie_id)
        if (dec.type == 'total' && this.contractinfo.dates) {
          this.contractinfo.dates[0].forEach(adate => {
            if (this.hourdata(adate)) {
              var check = this.hourdata(adate)[0].declaraties.filter(hc => { return hc.label == dec.name })[0]
              if (check&&!result && dec.attachment==1) {
                  result = parseFloat(check.value) > 0
                }
            }
          })
        }
      })      
      return result
    },
    needs_day_attachment(day) {
      var result = false
      this.contract.onregelmatig.forEach(onregel => {
        var dec = this.declaratie_by_id(onregel.declaratie_id)
        if (dec.type == 'total' && this.contractinfo.dates) {
          this.contractinfo.dates[0].forEach(adate => {
            if (adate==day && this.hourdata(adate)) {

              var check = this.hourdata(adate)[0].declaraties.filter(hc => { return hc.label == dec.name })[0]
              if (check&&!result && dec.attachment==1) {
                  result = parseFloat(check.value) > 0
                }
              }
          })
        }
      })
      return result
    },  
    showAbsent(){
      this.$bvModal.show('BSAbsentModalID')
    },  
   async close_week() {

      this.$modal.hide('popup_modal')
      if (this.needs_attachment()) {
        this.$modal.hide('popup_confirm')
        this.$modal.show(popup_confirm, {
          message: this.$t('labels.attachmentneededmessage'),
          header: this.$t('labels.attachmentneededheader'),
        }, {
          name: 'popup_confirm',
          draggable: false,
          height: 'auto',
          width: '90%'
        })
      } else {
        this.$modal.show(popup_modal, {
          message: this.settings.closeweekzzpr
        }, {
          name: 'popup_modal',
          draggable: false,
          height: 'auto',
          width: '90%'
        }, {
          'before-close': async () => {
            if (this.confirm_modal.id == 1) {
              this.backToUser()

              for (let hourdates of this.contractinfo.dates[0]) {
                let dayclose = false
                for (let ahour of this.hours) {
                  if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && ahour.user_id == this.contract.user_id) {
                    if (ahour.close_hours != 1) {
                      this.close_hours(ahour.id);
                      dayclose = true
                    }
                  }
                }
                if (!dayclose) {
                  let newhours = {
                    id: 0,
                    user_id: this.contract.user_id,
                    contract_id: this.contract_id,
                    workdate: hourdates,
                    hours: 0,
                    added_date: new Date().toUTCString(),
                    close_hours: 1
                  }
                  this.add_hours(newhours)

                }
              }
            
              this.$emit('clicked', 'someValue')
              // sent message to signer
              // console.log("SENDMAIL?????", this.userplanning.mailurencontact == 'yes', this.confirm_modal.id == 1, this.userplanning.hourregistration=='app')
              if ((this.userplanning.mailurencontact == 'yes' && this.confirm_modal.id == 1)&&(this.userplanning.hourregistration=='app')) {
                let email = this.user_by_id(this.userplanning.sign_id).email
                let name = this.username_by_id(this.userplanning.sign_id)
                let message = {}
                message.email = email
                message.name = name
                message.message = this.settings.hoursfilledmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
                message.message = message.message.replaceAll(/\[week\]/gi, this.weeknumber)
                message.message = message.message.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
                message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)
                
                message.message = message.message.replaceAll(/\[hoursoverview\]/gi, this.hoursoverviewHTML(this.contract_id,this.weeknumber<10?"0"+this.weeknumber + "-" + this.yearnumber:this.weeknumber + "-" + this.yearnumber ))

                message.subject = this.settings.hoursfilledsubject.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
                message.subject = message.subject.replaceAll(/\[week\]/gi, this.weeknumber)
                message.subject = message.subject.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
                message.subject = message.subject.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)

                message.maindocuments = []
                if(this.userplanning.copy_id){
                  message.cc = [this.user_by_id(this.userplanning.copy_id).email]
                }
                let emailnotification = parseInt(this.user_by_id(this.userplanning.sign_id).mail)==1
                console.log("SIGNER",this.user_by_id(this.userplanning.sign_id))
                // console.log(message)
                if(message.message.indexOf('confirmurl')!=-1){
                  let obj = {}
                  obj.contract_id = this.contract_id
                  obj.week = this.weeknumber
                  obj.year = this.yearnumber
                  await axios.post('/api/get_confirm_hash/', obj)  .then( async (response) => {
                    console.log('succes',response)
                    let encrypted = response.data
                    message.message = message.message.replaceAll(/\[confirmurl\]/gi, 'Klik hier voor akkoord <br><br><br><a style="text-decoration: none;background-color: #FC8007; border-color: #FC8007;color:#fff;text-align: center; vertical-align: middle;	font-size: 1rem; line-height: 1.5; padding: 0.75rem 1.5rem; border-radius: 2rem;cursor: pointer;height:24px;width:240px;" href="'+encrypted+'">Akkoord</a> ')
                    if(emailnotification) {
                      try {
                        axios.post('/api/contact_user', message).then((response) => {
                          console.log('succes', response)
                        })
                      } catch (err) {
                        console.log(err)
                      }
                    }
                  })
                } else {
                  if(emailnotification) {
                    try {
                      axios.post('/api/contact_user', message).then((response) => {
                        console.log('succes', response)
                      })
                    } catch (err) {
                      console.log(err)
                    }
                  }
                }
              }
              let inappnotification = parseInt(this.user_by_id(this.userplanning.sign_id).inapp)==1
              if(inappnotification) {
                // notify
                let message = {}
                message.message = this.settings.hoursfilledmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
                message.message = message.message.replaceAll(/\[week\]/gi, this.weeknumber)
                message.message = message.message.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
                message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)
                
                message.message = message.message.replaceAll(/\[hoursoverview\]/gi, "").replace(/<[^>]*>/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g,'')

                message.subject = this.settings.hoursfilledsubject.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
                message.subject = message.subject.replaceAll(/\[week\]/gi, this.weeknumber)
                message.subject = message.subject.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
                message.subject = message.subject.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname).replace(/<[^>]*>/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g,'')
                message.message = message.message.replaceAll(/\[confirmurl\]/gi, '')

                message.user_id = this.userplanning.sign_id
                message.contract_id = this.contract_id
                message.week = this.weeknumber
                message.year= this.yearnumber
                if(this.settings.PMHistory) {
                  this.add_pm( { id: 0, from:  this.contract.user_id, to: this.userplanning.sign_id, message:message })
                }
                try {
                  axios.post('/api/notify', message).then((response) => {
                    console.log('succes', response)
                  })
                } catch (err) {
                  console.log(err)
                }     
              }
              
            }
          }
        })
      }
      this.$emit('close')
    },
    sign_week(){
      // this.really_sign_week()
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
          message: this.settings.closeweeksigner
        }, {
          name: 'popup_modal',
          draggable: false,
          height: 'auto',
          width: '90%'
        }, {
          'before-close': () => {
            if (this.confirm_modal.id == 1) {
              this.really_sign_week()
            }
          }
        })
    },
    async really_sign_week() {
      this.signing = true;
      for (const hourdates of this.contractinfo.dates[0]) {
        if (this.hasdata(hourdates)) {
          for (var ahour of this.hours) {
            if (ahour.contract_id == this.contract_id && ahour.workdate == hourdates && ahour.user_id == this.contract.user_id) {
              if (ahour.agree_company != 1) {
                await this.agree_hours(ahour.id);
              }
            }
          }
        }
      }
      // add entry in facturen
      var newFact = { id: 0, week: this.weeknumber, contract_id: this.contract_id, user_id: this.contract.user_id }
      await this.add_factuur(newFact)

      // sent message to signer
      let email = this.user_by_id(this.userplanning.user_id).email
      let name = this.username_by_id(this.userplanning.user_id)
      let message = {}
      message.email = email
      message.name = name
      
      message.user_id = this.userplanning.user_id
      message.message = this.settings.hourscheckedmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
      message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)
      message.message = message.message.replaceAll(/\[week\]/gi, this.weeknumber)
      message.message = message.message.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)

      message.subject = this.settings.hourscheckedsubject.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)
      message.subject = message.subject.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
      message.subject = message.subject.replaceAll(/\[week\]/gi, this.weeknumber)
      message.subject = message.subject.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)

      message.maindocuments = []

      // console.log(">>CHECKED!!!!!", message)
      try {
        axios.post('/api/contact_user', message).then((response) => {
          console.log('succes', response)
          this.backToUser()

        })
      } catch (err) {
        console.log(err)
      }
        // notify
        message.message = this.settings.hourscheckedmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
        message.message = message.message.replaceAll(/\[week\]/gi, this.weeknumber)
        message.message = message.message.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
        message.message = message.message.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname)
        
        message.message = message.message.replaceAll(/\[hoursoverview\]/gi, "").replace(/<[^>]*>/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g,'')

        message.subject = this.settings.hourscheckedmessage.replaceAll(/\[zzper\]/gi, this.username_by_id(this.contract.user_id))
        message.subject = message.subject.replaceAll(/\[week\]/gi, this.weeknumber)
        message.subject = message.subject.replaceAll(/\[project_name\]/gi, this.userplanning.project_name)
        message.subject = message.subject.replaceAll(/\[signer\]/gi, this.user_by_id(this.userplanning.sign_id).firstname).replace(/<[^>]*>/g, '').replace(/&(nbsp|amp|quot|lt|gt);/g,'')

        message.user_id = this.userplanning.user_id
        message.contract_id = this.contract_id
        message.week = this.weeknumber
        message.year= this.yearnumber
        if(this.settings.PMHistory) {
          this.add_pm( { id: 0, to:  this.contract.user_id, from: this.userplanning.sign_id, message:message })
        }
        try {
          axios.post('/api/notify', message).then((response) => {
            console.log('succes', response)
          })
        } catch (err) {
          console.log(err)
        }   
        // get invoices 
        await this.get_facturen()
        let invoices = this.facturen.filter(invoice => invoice.contract_id==newFact.contract_id && invoice.week == newFact.week)

        for(const invoice of invoices) {
        // SendInvoiceOnChecked generate and send invoice to zpp'er
        //construct data for auto send invoice
        let sendinvoice = {}
          sendinvoice.id = invoice.id
          sendinvoice.user_id = this.contract_by_id(invoice.contract_id).user_id
          sendinvoice.contract_id = invoice.contract_id
          sendinvoice.week = invoice.week
          sendinvoice.hourcode = invoice.hourcode?parseInt(invoice.hourcode):null
          sendinvoice.dates = this.contractinfo.dates[0]
          sendinvoice.inkoopfactuur = this.userplanning.inkoopfactuur
          sendinvoice.invoiceemail= this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0] ? this.user_by_id(this.company_by_id(this.project_by_id(this.contract_by_id(invoice.contract_id).project_id).company_id).invoice_contants[0]).id : null
          if(this.settings.SendInvoiceOnChecked){
            await this.sendPurchaseInvoiceEmail(sendinvoice)
          }
          if(this.settings.SendInvoiceSaleOnChecked){
            await this.sendSaleInvoiceEmail(sendinvoice)
          }

        }



    },
    has_open_hours() {
      let has_unsigned = false
      this.contractHours(this.contract_id).forEach(tot => {
        if (tot.week == this.weeknumber) {
          has_unsigned = tot.waiting > 0
        }
      });
      return has_unsigned
    }
  },
  components: { FilledHours, UrenBonnen, UserHoursModal, BSUploadUserModal,BSAbsentModal },
}
</script>