<template>
  <b-container>
    <form class="form">
      <input type="hidden" :key="componentKey" />

        <div class="form-row">
          <div class="col-3">
            <label for="">{{ $t("labels.hourcategoryname") }}</label>
          </div>
          <div class="col">
            <select name="branche" class="form-control opdrachtgever" v-model="company.hourcategory_id" >
                <option value=null>{{ $t("option.nvt") }}</option>
                <option  v-for="(hourcategories,idx) in activehourcategories"  :key="idx" v-bind:value="hourcategories.id">
                    {{ hourcategories["name"]}}
                </option>
            </select>
          </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for="">{{ $t("labels.kvknumber") }}</label>
            </div>
            <div class="col">
                <b-form-input type="text" class="form-control"  v-model="company.kvkcode"></b-form-input>
            </div>
        </div>
        <div class="form-row">
          <div class="col-3">
            <label for="">{{ $t("labels.vat") }}</label>
          </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="company.vatnumber"/>             
            </div>
        </div>
        <div class="form-row">
            <div class="col-3">
                <label for="">{{ $t("labels.iban") }}</label>
            </div>
            <div class="col">
                <input type="text" class="form-control" v-model="company.iban">
            </div>
        </div>                                
        <div class="form-row">
            <div class="col-3">
                <label for="">{{ $t("labels.BIC") }}</label>
            </div>
          <div class="col">
              <v-select 
              :options="barebiccodes"
              v-model="company.bic" />
          </div>
        </div>   
        <div class="form-row">
            <div class="col-3">
                <label for="">{{ $t("labels.payment_term") }}</label>
            </div>
            <div class="col">
                <input type="text" class="form-control" v-model="company.betalingstermijn">
            </div>
        </div>   
        <div class="form-row">
            <div class="col-3">
                <label for="">{{ $t("labels.extra") }}</label>
            </div>
            <div class="col">
              <b-input-group>
                <b-form-textarea size="sm"  rows="3" :ref="extra" class="form-control" v-model="company.extra" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;" @click="append_data_multi('extra')"><font-awesome-icon icon="plus-circle" /> </b-button>
                    </b-input-group-append>                         
                </b-input-group>     
            </div>
        </div>  
        <div class="form-row">
          <div class="col-3">
              <label for=""> {{ $t("labels.ZZPdebnum") }}</label>
          </div>
          <div class="col">
              <b-form-input type="text" class="form-control" v-model="company.debnum"  />
          </div>     
        </div> 
        <div v-if="settings.useFinqle" class="form-row">
          <div class="col-3">
              <label for=""> {{ $t("meta.finqle") }}</label>
          </div>
          <div class="col">
              <b-form-input type="text" class="form-control" v-model="company.finqle"  />
          </div>     
          <div  class="col-3">
                <div v-if="company.finqle==null||company.finqle==''" class="btn btn-primary" @click="addToFinqle(); "><font-awesome-icon icon='plus'   />{{ $t("meta.finqle") }} {{ $t("buttons.add") }}
                </div>
            </div>
        </div>
        <div v-if="settings.useFinqle" class="form-row">
          <div class="col-3">
              <label for=""></label>
          </div>
          <div class="col">
            <b-form-checkbox  v-model="company.chargefinqle"
          name="checkbox-1"
          value="1"
          unchecked-value="0">{{ $t("labels.chargefinqle") }}</b-form-checkbox>
          </div>     
        </div>         
      <div class="form-row" >
        <div class="col-3" v-if="settings.RegisterWorkhours">
          {{ $t("labels.workhours") }}
        </div>
        <div class="col-2" v-if="settings.RegisterWorkhours">
          <b-form-group>
            <b-form-radio v-model="company.workhours" value="no">{{ $t("labels.no") }}</b-form-radio>
            <b-form-radio v-model="company.workhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
          </b-form-group>
        </div>
        <div class="col-3"  v-if="settings.SignWorkhours">
          {{ $t("labels.signworkhours") }}
        </div>
        <div class="col-2" v-if="settings.SignWorkhours">
          <b-form-group>
            <b-form-radio v-model="company.signworkhours" value="no">{{ $t("labels.no") }}</b-form-radio>
            <b-form-radio v-model="company.signworkhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
          </b-form-group>
        </div>
      </div>
    <div class="form-row">
      <div class="col-3"  >
        {{ $t("labels.attachmentneededheader") }}
      </div>
      <div class="col-2">
        <b-form-group>
          <b-form-radio v-model="company.needsattachment" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="company.needsattachment" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-3"  v-if="settings.EnableBlacklist"></div>
        <div class="col-3" v-if="settings.EnableBlacklist">
        <b-form-checkbox  v-model="company.blacklist"
          name="checkbox-1"
          value="1"
          unchecked-value="0">{{ $t("labels.blacklist") }}</b-form-checkbox>
      </div>   


    </div>                                      
    </form>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'companysettings',
    data() {
        return {
            componentKey:0,
            extra:"extra",
            
        }
    },
    props: {
        company_id: Number
    },
    mounted() {
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },
        ...mapGetters([
            'companies_metas',
            'company_by_id',
            'opdrachtgevers',
            'activebranches',
            'activehourcategories',
            'company_new',
            'createAny',
            'owners',
            'append_data_string',
            'barebiccodes'
            
        ]),
        ...mapState([
            'companies',
            'branches',
            'users'
        ]),
          
    },
    methods: {
        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },
        append_data_multi(key){
            var current= ""
            if(this.company[key]){
                current="\n"+this.company[key]
            }
            this.company[key]=this.append_data_string+current
            this.componentKey += 1;
            if(this.$refs[key]){
                if(this.$refs[key][0]){
                    this.$refs[key][0].focus()
                    this.$nextTick(() => {
                      this.$refs[key][0].selectionEnd = 9
                    })                    
                }else{
                    this.$refs[key].focus()
                    this.$nextTick(() => {
                      this.$refs[key].selectionEnd = 9
                    })                    
                }
            }
        },   
        async addToFinqle(){
          const response = await axios.get('/api/finqledebtors/')
          // console.log(response)
          // console.log(response.data.data.debtors.data.filter(d => d.company.registrationNumber== this.company.kvkcode))
          if(response && response.data){
            // console.log(response.data)
            // console.log(response.data.data)
            // console.log(response.data.data.createDebtor)
            // console.log(response.data.data.createDebtor.id)
            this.company.finqle = response.data.data.debtors.data.filter(d => d.company.registrationNumber== this.company.kvkcode)[0].id
            this.componentKey++
          }
        }        

    }
}
</script>            