<template>
  <b-container v-if="createAny('users')">
    <b-row>
      <b-col cols="8">
        <h1>{{ $t("labels.branchename") }} <strong> {{branche.name}}</strong></h1>
      </b-col>
      <b-col>
        <div @click="edit_workfield({ id: 0})" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.addworkfield") }}
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table hover :items="workfields_in_branche" :fields="fields" @row-clicked="onWorkfieldClicked">
          <template #cell(active)="row">
            <font-awesome-icon v-if="row.item.active" icon="check" style="color: green;"
              :title="$t('labels.editworkfieldoff')" />
            <font-awesome-icon v-else icon="times" style="color: red;" :title="$t('labels.editworkfieldon')" />
          </template>
          <template #cell(pulldown)="row">
            <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
              <b-dropdown-item @click="confirm_delete_workfield(row.item.id)">
                <font-awesome-icon icon="trash" /> {{$t('labels.deleteworkfield')}}
              </b-dropdown-item>
              <b-dropdown-item @click="edit_workfield(row.item.id)">
                <font-awesome-icon icon="cog" /> {{$t('labels.editworkfield')}}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>

</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import workfield_modal from './modals/WorkfieldModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'branchescreen',
  data() {
    return {
      selectedID: null
    }
  },
  computed: {
    fields() {
      return [
        { key: 'name', label: this.$i18n.t('labels.workfieldname'), sortable: true, sortDirection: 'desc' },
        { key: 'active', label: this.$i18n.t('labels.active') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    ...mapState([
      'branches',
      'workfields',
    ]),
    ...mapGetters([
      'branche_by_id',
      'workfield_by_id',
      'confirm_modal',
      'createAny'

    ]),
    workfields_in_branche: {
      get() {
        return this.workfields.filter(wf =>wf.branche_id == this.branche.id)
      }
    },
    branche: {
      get() {
        return this.branche_by_id(this.$route.params.id)
      },
    },
  },
  methods: {

    confirm_delete_workfield(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.workfield_by_id(id).name + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_workfield(this.selectedID);
        for (let i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].id == this.selectedID) {
            this.workfields.splice(i, 1);
          }
        }
      }
    },
    ...mapActions([
      'add_workfield',
      'save_workfield',
      'delete_workfield'
    ]),
    edit_workfield(workfield) {
      this.$modal.hide('workfield_modal')
      this.$modal.show(workfield_modal, {
        workfield_id: workfield,
        branche_id: this.branche.id
      }, {
        name: 'workfield_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px'
      })

      this.$emit('close')
    },
    onWorkfieldClicked(row) {
      let workfieldclicked = this.workfield_by_id(row.id)
      if (workfieldclicked.active == 1) {
        workfieldclicked.active = 0
      } else {
        workfieldclicked.active = 1
      }
      this.save_workfield(row.id)
    },
  },
}
</script>

